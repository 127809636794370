/*global gADataLayerPushEvents */

var boxVideoLinksSelector = '.box-featured__video-thumb-link, .box-listing-element__video-thumb-link',
    breakpointWidePX = breakpointWideEM * bodyFontSize,
    defaultVideoTitle = 'Monash University video',
    mqWide = '(min-width: 60em)',
    mqListener = window.matchMedia(mqWide),
    popupRemovalDelay = 160,
    bodyFontSize = Number(getComputedStyle(document.body, '').fontSize.match(/(\d*(\.\d*)?)px/)[1]),
    breakpointWideEM = 60;



/* Inject embedded Vimeo or YouTube iframe for box listing videos
     * at mobile size only.
     */
function handleBoxVideos(mql) {
    var videoLinks = $(boxVideoLinksSelector),
        videoWrapperSelector = '.video-container';
    if (mql.matches) {
        videoLinks.each(function(i, el) {
            // Show video thumbnail (for modal) at wide screen size and above.
            $(el).show();
            // Hide existing embedded video iframe.
            $(el).next(videoWrapperSelector).hide();
        });
        videoLinks.show();
    }
    else {
        videoLinks.each(function(i, el) { // eslint-disable-line consistent-return
            var $el = $(el),
                videoID = $el.data('video-id'),
                videoURL = $el.prop("href"),
                videoEmbedHTML = '';
            // Hide video thumbnail (for modal) at mobile screen size.
            $el.hide();
            // Show existing embedded video iframe.
            $el.next(videoWrapperSelector).show();
            /* Don't create embedded iframe video if:
             * - There is no video ID; or
             * - An iframe already exists
             */
            if (typeof videoID === 'undefined' || !videoID || $el.next(videoWrapperSelector).length) {
                return true;
            }
            if ( videoURL.toLowerCase().indexOf("vimeo") >= 0 ) {
                videoEmbedHTML = '<div class="video-container"><iframe src="https://player.vimeo.com/video/' + videoID + '" frameborder="0" allowfullscreen></iframe></div>';
            } else {
                videoEmbedHTML = '<div class="video-container"><iframe src="https://www.youtube.com/embed/' + videoID + '?showinfo=0" frameborder="0" allowfullscreen></iframe></div>';
            }
            // Inject Vimeo or YouTube video iframe if videoURL exists
            $(videoEmbedHTML).insertAfter($(el)).show();
        });
    }
}


var boxVideosInit = function() {
    var iframeOptions = {
        patterns: {
            youtube_short: { // eslint-disable-line camelcase
                index: 'youtube.com/',
                id: function(src) {
                    // link pattern
                    var mt = src.match(/v=([^&]+)(&(.*))?/);
                    
                    if (mt) {
                        return mt[1] +'?'+ mt[3];
                    }

                    // embed pattern
                    mt = src.match(/embed\/([^\?\/]+)(\?(.*))?/);
                    if (mt) {
                        return mt[1] +'?'+ mt[3];
                    }
                },
                src: '//www.youtube.com/embed/%id%&autoplay=1&rel=0' 
            },
            youtube: {
                index: 'youtu.be/',
                id:    'youtu.be/',
                src:   '//www.youtube.com/embed/%id%?autoplay=1'
            }
        }
    };

    // Handle feature box and listing box video behaviour on page load and screen resize
    mqListener.addListener(handleBoxVideos);
    handleBoxVideos(mqListener);

    /* Display video in modal at ≥ wide size only. */
    $(boxVideoLinksSelector).magnificPopup({
        type: 'iframe',
        disableOn: breakpointWidePX,
        removalDelay: popupRemovalDelay,
        preloader: false,
        fixedContentPos: false,
        iframe: iframeOptions,
        callbacks: {
            markupParse: function(template, values, item) {
                var videoTitle = item.el.eq(0).attr('title') || defaultVideoTitle,
                    videoFrame = template.find('iframe');
                videoFrame.attr('title', videoTitle);
            }
        }
    });
};

(function($) {

    /* The following modules have video variants which play a video in a modal:
     *
     * - box-featured
     * - box-listing-element
     *
     * Behaviour is as follows:
     *
     * - Mobile: hide video thumbnail, inject embedded iframe
     * - ≥ Wide: open video inside modal
     */


    /* Toggle carousel pause when a modal is opened/closed:
     *
     * - When a modal is opened, pause all animating carousels on the page.
     * - When a modal is closed, resume all animating carousels on the page.
     */
    function toggleAutoplayCarousels(pause) {
        var carousels = $('.banner__carousel');
        carousels.each(function(i, el) {
            var togglePause = $(el).find('.slick-toggle-pause').eq(0);
            // Carousel has been manually paused by user - do nothing
            if (togglePause.length && togglePause.attr('aria-pressed') === 'true') {
                return true;
            }
            // Pause all carousels before opening modal
            if (pause) {
                $(el).slick('slickPause');
            }
            // Unpause all carousels after modal is closed
            else {
                $(el).slick('slickPlay');
            }
            return false;
        });
    }

    boxVideosInit();

    /* Banners always display a modal because the imagery and
     * the Monash "M" branding is important, so the banner image
     * must always be displayed at all screen sizes.
     *
     * This means the user will have to tap twice to play the video:
     * - First tap: launch modal with embedded video
     * - Second tap: play the embedded video
     *
     * Autoplay of the video in the modal is not possible, as most
     * OSes prohibit this behaviour to save on bandwidth:
     *
     * https://developer.apple.com/library/safari/documentation/AudioVideo/Conceptual/Using_HTML5_Audio_Video/Device-SpecificConsiderations/Device-SpecificConsiderations.html
     */
    $('.banner__carousel-video-link, .banner__video-link').magnificPopup({
        type:            'iframe',
        removalDelay:    popupRemovalDelay,
        preloader:       false,
        fixedContentPos: false,
        callbacks:       {
            beforeOpen: function() {
                toggleAutoplayCarousels(true);
            },
            afterClose: function() {
                toggleAutoplayCarousels(false);
            }
        }
    });

    //Fire dataLayer.push() when the video lightbox is open
    $('.js-listing-box-video-link').on('mfpOpen', function() {
        var $playButton = $(this),
            $parentBox = $playButton.closest('.box-listing-element'),
            eventTitle = $parentBox.find('h3').text() +
                        ' - ' +
                        $parentBox.find('.box-listing-element__description').text();
        gADataLayerPushEvents({
            'event': 'Lightbox open',
            'title': eventTitle
        });
    });

}(jQuery));
