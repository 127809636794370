(function($){

    /*
     * No parallax animations to init - bail out to prevent page
     * from slowing down.
     */
    if (!$('.parallax').length) {
        return;
    }

    var ua = window.navigator.userAgent,
        appName = window.navigator.appName,
        isIE = false;

    if (appName === 'Microsoft Internet Explorer' && ua.indexOf('MSIE ')
    || /Trident/.test(ua) && appName === 'Netscape') {
        isIE = true;
    }

    // Parallax behavior is generally undesirable on
    // mobile devices or ie8 and older due to performance issues
    // and screen real-estate. If you want parallax behavior to
    // init and destroy on screen resize consider a listener on a
    // window.matchMedia media query list object and call:
    // parallax.destroy()
    var mqWide = '(min-width: 60em)',
        mqListener = window.matchMedia(mqWide);

    /* Apply parallax animation at ≥ wide screen size. */
    function handleBannerParallax(mql) {
        var debounceSpeed = 5;
        if (mql.matches) {
            // Init parallax
            $('.parallax').SquizParallax({ // eslint-disable-line new-cap
                smoothScroll:   false,
                debounceScroll: isIE ? debounceSpeed : 0
            });
        }
        else {
            // Destroy parallax
            if (typeof $('.parallax').data('plugin_SquizParallax') !== 'undefined'
            && $('.parallax').data('plugin_SquizParallax')) {
                $('.parallax').data('plugin_SquizParallax').destroy();
            }
        }
    }

    mqListener.addListener(handleBannerParallax);
    handleBannerParallax(mqListener);

}(jQuery));
