/* global yearsArray, yearPreloaded, startYear, endYear, excludedYear  */
var yearsLoaded = [];    

// Handle preloading cards
var yearPreloaded = {
    nextYear:     0,
    goingForward: true,
    xhr:          new XMLHttpRequest(),
    years:        [],
    status:       0,   // 0 - initial, 1 - loading, 2 - loaded
    yearsHTML:    '',
    init:         function() {
        var _this = this;
        _this.xhr.onreadystatechange = function() {
            if (_this.xhr.readyState === 4) {
                if (_this.xhr.status === 200) {
                    _this.yearsHTML = $.parseHTML(_this.xhr.responseText);
                    _this.status = 2;

                    // Got to next year 
                    _this.takeFromPreloaded(_this.nextYear);

                }
            }
        };
    },
    checkPreloadingStatus: function(currentSlide, nextSlide) {
        var _this = this,
            currentSlide = currentSlide,
            nextSlide = nextSlide,
            currentYear = parseInt($('.timeline__slider-item[data-slick-index="'+currentSlide+'"]').attr('data-year'),10),
            nextYear = parseInt($('.timeline__slider-item[data-slick-index="'+nextSlide+'"]').attr('data-year'),10),
            yearSwap = currentYear !== nextYear ? true : false,
            isOnFirstYear = nextYear === yearsLoaded[0] ? true : false,
            isOnLastYear = nextYear === yearsLoaded[yearsLoaded.length-1] ? true : false; 

        // Years have changed
        // 1a) if next year is smaller then the past one (you are going left) preload current year and -4 years
        // 1b) if next year is greated then the past one (you are going right) preload current year and +4 years
        // 2) and then jump to "next" year 
        if (yearSwap) {
            
            // Jump to this year after loading slides
            yearPreloaded.nextYear = nextYear;

            // Going left
            if (isOnFirstYear) {
                yearPreloaded.goingForward = false;
                _this.preloadCards(currentYear-4, currentYear);
            }

            // Going right
            if (isOnLastYear) {
                yearPreloaded.goingForward = true;
                _this.preloadCards(currentYear, currentYear+4);
            }

        }
    },
    preloadCards: function(startYear, endYear) {
        if (this.status !== 1) {
            if (this.years[0] !== startYear || this.years[1] !== endYear) {

                // Show loader
                isLoading(true);

                var url = document.querySelector('.js-timeline-landing-ajax').getAttribute('data-ajax-url'),
                    //getParams = '?queries_year_query_fromvalue[d]=1&queries_year_query_fromvalue[m]=1&queries_year_query_fromvalue[y]='+startYear+'&queries_year_query_toshow=ymd&queries_year_query_tovalue[d]=31&queries_year_query_tovalue[m]=12&queries_year_query_tovalue[y]='+endYear;
                    getParams = '?years='+buildYearsRange(startYear, endYear);

                this.status = 1;
                this.years = [startYear, endYear];

                this.xhr.open('GET', url+getParams);
                this.xhr.send();
            } 
        } 
    },
    takeFromPreloaded: function(year) {
        var _this = this,
            targetClass = document.querySelector('.js-timeline-landing-ajax').getAttribute('data-ajax-content-target'),
            targetEl = document.querySelector(targetClass);

        // Unslick
        $('.js-timeline-slick').slick('unslick');

        // Remove all slides
        $(targetEl).html('');

        // Push new slides
        $(targetEl).append(yearPreloaded.yearsHTML);

        // Reinit
        slickReinit();

        // Get years that are loaded
        getYearsLoaded();

        // Go to year
        goToYear(year);

        // Set colours
        slick.setColours();

        // Reload addthis
        reloadAddthis();

        // If going backwards start from last slide
        if (!this.goingForward) {
            setTimeout(function(){                    
                var lastSlideFromYear = parseInt($('.timeline__slider-item:not(.slick-cloned)[data-year="'+_this.nextYear+'"]:last').attr('data-slick-index'), 10);
                $('.timeline__slider').slick('slickGoTo', lastSlideFromYear);

                // Hide loader
                isLoading(false);

            },2000);
        } else {

            // Hide loader
            isLoading(false);

        }
    }
};

function reloadAddthis() {
    addthis.toolbox('.social-media-share'); 
};

function yearArrayBuilder() {
    for(var y=startYear; y<=endYear; y+=1) {
        if (excludedYear.indexOf(y) === -1) {
            yearsArray.push(y);
        }
    }
};

function initTimelineLanding(initYear, initSlide) {
    // Init timeline slider
    slick.init($('.js-timeline-slick'));

    // Init cards desktop-mobile arranging mechanism
    cardsOrganiser.arrange();

    // Got to slide based on hash
    if (initYear) {

        // Show loader
        isLoading(true);

        goToYear(initYear);
    }

    // Go to specific slide based on hash
    if (initSlide) {
        setTimeout(function(){
            
            var initSlideIndex = parseInt($('.timeline__slider-item:not(.slick-cloned)[data-id="'+initSlide+'"]').attr('data-slick-index'), 10);
            $('.timeline__slider').slick('slickGoTo', initSlideIndex);

            // Hide loader
            isLoading(false);

        },2000);
    } else {
         
         // Hide loader
         isLoading(false);
    }

    // Workaround for IE issues with object-fit css property
    if (detectIE()) {
        objectFit('.timeline-card--video:not(.timeline-card--video-full) .box-listing-element__video-thumb-image', {
            'display':      'flex',
            'adjustHeight': true
        });
    }
}

function getCards(url, target, addThisMarkup, initYear, initSlide) {
    'use strict';
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                const parsedHTML = $.parseHTML(xhr.responseText);
                $(target).append(parsedHTML);
                initTimelineLanding(initYear, initSlide);
                // Check if addThis script is supposed to be added
                if (addThisMarkup) {
                    $('body').append(addThisMarkup);
                }

                // Set current years
                getYearsLoaded();
            }
        }
    };
    xhr.open('GET', url);
    xhr.send();
}

function getYearsLoaded() {
    yearsLoaded = [];
    $('.timeline__slider-item').each(function(i,item){
        var year = parseInt($(item).attr('data-year'), 10);
        if (yearsLoaded.indexOf(year) === -1) {
            yearsLoaded.push(year);
        }
    });
    yearsLoaded.sort();
};

function goToYear(year) {
    var goTo = $('.timeline__slider .timeline__slider-item[data-year="'+year+'"]:not(.slick-cloned)').attr('data-slick-index'),
        goTo = parseInt(goTo, 10);
    $('.timeline__slider').slick('slickGoTo',goTo);
};

function isLoading(status) {
    if (status) {
        $('.timeline').addClass('timeline--loading');
    } else {
        $('.timeline').removeClass('timeline--loading');
    }
}

function regetCards(startYear,endYear,slideToYear) {
    'use strict';

    // Show loader
    isLoading(true);

    var xhr = new XMLHttpRequest(),
        targetClass = document.querySelector('.js-timeline-landing-ajax').getAttribute('data-ajax-content-target'),
        targetEl = document.querySelector(targetClass),
        url = document.querySelector('.js-timeline-landing-ajax').getAttribute('data-ajax-url'),
        //getParams = '?queries_year_query_fromvalue[d]=1&queries_year_query_fromvalue[m]=1&queries_year_query_fromvalue[y]='+startYear+'&queries_year_query_toshow=ymd&queries_year_query_tovalue[d]=31&queries_year_query_tovalue[m]=12&queries_year_query_tovalue[y]='+endYear;
        getParams = '?years='+buildYearsRange(startYear, endYear);

    xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                
                // Unslick
                $('.js-timeline-slick').slick('unslick');

                // Remove all slides
                $(targetEl).html('');

                // Append new slides
                var parsedHTML = $.parseHTML(xhr.responseText);
                $(targetEl).append(parsedHTML);

                // Reinit
                slickReinit();

                // Get years that are loaded
                getYearsLoaded();

                // Go to year
                goToYear(slideToYear);

                // Set colours
                slick.setColours();

                // Reload addthis
                reloadAddthis();

                // Hide loader
                isLoading(false);

            }
        }
    };

    xhr.open('GET', url+getParams);
    xhr.send();

}

// Handle hash for card navigation
var hashHandler = {

    set: function(year, id) {
        var baseUrl = window.location.href.split('#')[0];
        window.location.replace(baseUrl+'#'+year+'-'+id);
    }, 

    get: function() {
        if (window.location.hash.length !== 0) {
            var startSlide = window.location.hash.replace('#','').split('-'),
                startSlideDate = {
                    'year': yearsArray.indexOf(parseInt(startSlide[0], 10)) !== -1 ? parseInt(startSlide[0], 10) : false,
                    'id':   !isNaN(parseInt(startSlide[1], 10)) ? parseInt(startSlide[1], 10) : false
                };

            return startSlideDate;
        } else {
            return false;
        }
    }

};

// Build years list range as csv
var buildYearsRange = function(fromYear,toYear) {
    var fromYear    = parseInt(fromYear, 10),
        toYear      = parseInt(toYear, 10),
        returnArray = [];

        for(var i=fromYear;i<=toYear;i+=1) {
            returnArray.push(i);
        }

        returnArray = returnArray.join(',');

        return returnArray;
};

// Init
(function () {
    if (document.querySelector('.timeline')) {
        // Init additional plugin required for a proper swiping of the cards on mobile
        yearArrayBuilder();
        initExtraPlugins();
        yearPreloaded.init();

        const ajaxWrapper = document.querySelector('.js-timeline-landing-ajax');

        // Check hash on page load
        var startDate = hashHandler.get(),
            startYear = 1957,
            endYear = 1959,
            initYear = false,
            initSlide = false;

        if (startDate !== false) {
            if (yearsArray.indexOf(startDate.year) !== -1) {
                startYear = startDate.year-1;
                endYear = startDate.year+1;
                initYear = startDate.year;
                initSlide = startDate.id;
            }
        } 

        if (ajaxWrapper) {
            const targetClass = ajaxWrapper.getAttribute('data-ajax-content-target');
            const targetEl = document.querySelector(targetClass);
            const url = ajaxWrapper.getAttribute('data-ajax-url');
            //const initialParams = '?queries_year_query_fromvalue[d]=1&queries_year_query_fromvalue[m]=1&queries_year_query_fromvalue[y]='+startYear+'&queries_year_query_toshow=ymd&queries_year_query_tovalue[d]=31&queries_year_query_tovalue[m]=12&queries_year_query_tovalue[y]='+endYear;
            const initialParams = '?years='+buildYearsRange(startYear, endYear);
            getCards(url+initialParams, targetEl, ajaxWrapper.getAttribute('data-addthis-markup'), initYear, initSlide);
        } else {
            window.onload = () => {
                initTimelineLanding();
            };
        }

        document.getElementById('page-wrapper').classList.add('page-timeline-landing');
    }
}());
