/* LinkBandit: hijack link appearance and behaviour to flag external links */

(function($) {

    var linkSelector = '.content-wrapper > a, '
            + '.content-wrapper > p a, '
            + '.content-inner__main a:not(.area-blocks__link):not(.study-info-extra__link)'
            + ':not(.box-featured__thumb-link):not(.box-featured__video-thumb-link):not(.box-featured__heading-link):not(.section-cards .panels)',
        internalLocationsField = $('meta[name="site\\.internal\\.domains"]');
    /* Compare target links against user-defined domains in metadata field */
    if (internalLocationsField.length) {
        LinkBandit.init(linkSelector, internalLocationsField.attr('content').split('; '), 'link--external', $); // eslint-disable-line no-undef, max-len
    }

}(jQuery));
