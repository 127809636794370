/*global $, jQuery, CourseSlider, initAccordions, initTooltips*/

var tabEl = {
    init: function () {
        this.mobileCtrl();
    },
    mobileCtrl: function () {
        var mobileFlag = false,
            minMq = '(min-width: 60em)',
            tabObj = this;
        window.matchMedia(minMq).addListener(function (mql) {
            if (!mql.matches) {
                if (!mobileFlag) {
                    tabObj.initAccordionMutator();
                    mobileFlag = true;
                }
            }
        });
        if (!window.matchMedia(minMq).matches) {
            if (!mobileFlag) {
                tabObj.initAccordionMutator();
                mobileFlag = true;
            }
        }
    },

    addMobileIds: function(html) {
        var $obj = $(html),
            $modified = $('<div />'),
            mobileAppendString = '-mobile',
            thisObj = this;

        $modified.append($obj);

        thisObj.mobileAttrReplacer($modified.find('*[id]'), 'id', mobileAppendString);
        thisObj.mobileAttrReplacer($modified.find('*[headers]'), 'headers', mobileAppendString);
        thisObj.mobileAttrReplacer($modified.find('[data-rel-to-mobile]'), 'rel', mobileAppendString);

        return $modified.html();
    },
    mobileAttrReplacer: function(elements, attrToReplace, stringToAppend) {
        elements.each(function(){
            var _this = $(this);
            _this.attr(attrToReplace, _this.attr(attrToReplace) + stringToAppend);
        });
    },
    initAccordionMutator: function () {
        var arrayEls = [],
            sliders = [],
            context = this;

        //Mutate Standard Tabs to accordions
        $('.tabs').not('.no-mobile-accordion').not('.tabs--nested').each(function() {
            context.createAccordion($(this), arrayEls, sliders, context);
        });

        //Mutate Nested Tabs to accordions
        $('.tabs.tabs--nested').each(function() {
            context.createAccordion($(this), arrayEls, sliders, context);
        });

        // Change id's for course slider and fire the slider for mobile
        $('#bottom-tabs > .accordion.desktop-hidden section')
            .each(function (i, item) { // eslint-disable-line no-unused-vars
                if ($(this).find('> .accContent').find('.slider-tab-content').length) {
                    var tabID = $(this).find('> .accContent').find('.slider-tab-content').attr('id'),
                        loadedID = $(this).find('> .accContent').find('.slider-loaded-content').attr('id');

                    $(this).find('> .accContent').find('.slider-tab-content').attr('id', tabID);
                    $(this).find('> .accContent').find('.slider-loaded-content').attr('id', loadedID);

                    sliders[i] = new CourseSlider({
                        parent:  $('#' + tabID),
                        target:  $('#' + loadedID),
                        trigger: $('#' + tabID + ' a.slideRightBtn')
                    });

                    sliders[i].init();
                }
            });
    },
    createAccordion: function($tabsgroup, arrayEls, sliders, context) {
        arrayEls = [];
        sliders = [];

        $tabsgroup.find('.tabs__tab-list').eq(0).find('.tabs__link').each(function () {
            var tabHeader,
                tabContent,
                tabItemObj,
                regEx = new RegExp('=', 'g');

            tabHeader = $(this).html();
            tabContent = $($(this).attr('href')).html();

            tabItemObj = {
                'header':  tabHeader,
                'content': tabContent,
                'id':      'accordion-' +
                            window.btoa($(this).attr('href')).replace(regEx, '') //Avoid having duplicated ids
            };
            //Fill an array with all the tab headers and contents
            arrayEls.push(tabItemObj);
        });

        //Build the accordion structure
        var accordionContents = '';

        accordionContents += '<div multiselectable="true" role="tablist" class="accordion desktop-hidden">';

        $.each(arrayEls, function (key, tabElement) {
            accordionContents +=
                '<section class="panel">' +
                '<h3 class="accTitle" id="' + tabElement.id +
                '" aria-controls="' + tabElement.id +
                '" role="tab" aria-expanded="false"><a href="#accordion__target-' +
                tabElement.id + '">' + tabElement.header + '</a></h3>' +
                '<div id="accordion__target-' + tabElement.id + '"class="accContent" aria-labelledby="' +
                tabElement.id + '" aria-hidden="true" role="tabpanel" style="display: none;">' +
                context.addMobileIds(tabElement.content) + '</div>' + '</section>';
        });
        accordionContents += '</div>';

        $tabsgroup.after($(accordionContents));

        try {
            //Init tooltips
            initTooltips(); //Only used for study.Monash. Function provided in 'at-a-glance' module in there.
        }
        catch(err) {
            console.log('init tooltips');
        }

        //Init accordions
        initAccordions($tabsgroup.next('.accordion'));
    }

};

(function($) { // eslint-disable-line no-unused-vars
    tabEl.init();
}(jQuery));
