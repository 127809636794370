/*global dataLayer */

var vars = {
    mqWide:                      '(min-width: 60em)',
    viewMoreBtnSelector:         '.js-view-more',
    viewMoreDestinationSelector: '.js-view-more-dest',
    masonrySelector:             '.js-masonry-enabled',
    historyHTMLSelector:         '.js-history-enabled',
    spinnerHTML:                 '<div class = "clearfix">&nbsp;</div><div class="spinner view-more"><div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div></div>' //eslint-disable-line max-len
};

// View more function
// including Spinner http://tobiasahlin.com/spinkit/ (3rd one) and imagesloaded + masonry for desktop only
// this also utilises browser History API to retain the markup when user hit 'Back button'
// To initialise, these elements are required
//  - a button with class 'js-view-more' which will have the viewMoreAction execute on click
//  - js-view-more button needs to have 'data-src' attribute set to the HTML source of the additional content
//  - the destination of the additional contents will be decided by:
//      - 'data-dest' on 'js-view-more' button which is a selector, OTHERWISE (if there's no data-dest)
//      - an element with class 'js-view-more-dest'
var viewMoreHandlers = {
    init: function() {
        var _this = this;
        $(vars.viewMoreBtnSelector).each(function() {
            var $btn = $(this),
                src = $btn.data('src'),
                $dest;

            // get destination location from data-dest attribute, otherwise, just get the div before the button
            $dest = $($btn.data('dest')) || $btn.prev(vars.viewMoreDestinationSelector);

            // if loadmore event hasn't been init yet
            if (!$btn.data('loadmore-init') && src && $dest) {
                $btn.click(function() {
                    _this.viewMoreAction($btn, src, $dest);
                });
                // prevent loadmore event to be added more than once
                $btn.data('loadmore-init', true);
            }
        });
    },

    // replace the button with spinner
    loadSpinner: function($btn) {
        $(vars.spinnerHTML).insertAfter($btn);
        $btn.remove();
    },

    // load more
    // $btn (jQuery object) - 'Load more' button to be replaced by spinner
    // src (string) - URL to load the markup from, usually found in 'data-src' attribute of the button
    // $location (jQuery object) - block to append the new markup to
    viewMoreAction: function($btn, src, $location) {
        var _this = this;
        $.ajax({
            url:        src,
            beforeSend: function() {
                _this.loadSpinner($btn);
            }
        })
            .done(function (data) {

                // extract view more button from the data
                var $temp = $('<div />').html(data),
                    $viewMore = $temp.find(vars.viewMoreBtnSelector).remove(),
                    $data = $($temp.html());

                // if it's desktop view, append the HTML and init masonry
                // otherwise, just append HTML only
                if (window.matchMedia(vars.mqWide).matches) {
                    $location.masonry()
                        .append($data).imagesLoaded({}, function() {
                            // Add view more button && init listener
                            $viewMore.insertAfter($location);
                            _this.init();

                            // remove the spinner
                            $location.nextAll('.clearfix, .spinner.view-more').remove();

                            // execute append animation of masonry
                            $location.masonry('appended', $data)
                                .on('layoutComplete', function() {
                                    // push to history
                                    history.pushState($(vars.historyHTMLSelector).html(), 'htmlLoaded');
                                })
                                .masonry('layout');
                        });
                } else {
                    $location.append($data);
                    $viewMore.insertAfter($location);
                    _this.init();
                    $location.nextAll('.clearfix, .spinner.view-more').remove();
                    history.pushState($(vars.historyHTMLSelector).html(), 'htmlLoaded');
                }
            });
    }
};

// init masonry (desktop only), masonry would be destroyed on mobile
// the init function will execute masonry on elements that have class 'js-masonry-enabled'
var masonryHandlers = {
    init: function(mql) {
        var $masonry = $(vars.masonrySelector);
        if ($masonry) {
            if (mql.matches) {
                $masonry.each(function() {
                    var $this = $(this);
                    $this.imagesLoaded({}, function() {
                        $this.masonry();
                    });
                });
            } else {
                $masonry.each(function() {
                    $(this).masonry().masonry('destroy');
                });
            }
        }
        return true;
    }
};

//GA Data Layer push events helper
var gADataLayerPushEvents = function(object){ // eslint-disable-line no-unused-vars
    if (typeof object !== 'undefined' && typeof dataLayer !== 'undefined' ) {
        //console.log('dataLayer push event: ' + object.event); //For Debugging
        dataLayer.push(object);
    }
};

(function($) {
    // load content of history.state to a history container element
    // a history container element needs to have 'js-history-enabled' class applied
    // init masonry & viewMore after loading
    var $historyElem = $(vars.historyHTMLSelector),
        mqListener = window.matchMedia(vars.mqWide),
        initFunctions = function() {
            mqListener.addListener(masonryHandlers.init);
            masonryHandlers.init(mqListener);
            viewMoreHandlers.init();
        };
    if ($historyElem && history.state) {
        $historyElem.html(history.state);
        initFunctions();
    } else {
        initFunctions();
    }
}(jQuery));
