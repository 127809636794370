var custWYSIWYGBuilder = {
    init: function(el){
        custWYSIWYGBuilder.loadEditor(el);
    },
    loadEditor: function(el) {
        var svgPath = el.attr('data-icons-url'); //path for the toolbar icons svg
        var $el = $(el);
        //Init the plugin
        $el.trumbowyg({
            svgPath: svgPath,
            removeformatPasted: true
        }).on('tbwchange', function(){
            $el.text($el.val());
        });
    }
};

(function() {
    $('textarea.js-cust-wysiwyg').each(function(el) { custWYSIWYGBuilder.init($(this)) });
}(jQuery));
