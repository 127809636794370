(function ($) {
    var isIE11 = !!navigator.userAgent.match(/Trident.*rv\:11\./);
    var thumbLinks = $('.box-featured__thumb-link, .box-featured__video-thumb-link, .box-featured__thumb-wrapper');
    if (isIE11 == true) {
        $(window).on('resize', function(){
            thumbLinks.imagesLoaded(function () {
                // IE11 doesn't calculate image height correctly when deeply nested in flex column
                thumbLinks.each(function (i, el) {
                    var thumbnail = $(el).find('.box-featured__thumb-image, .box-featured__video-thumb-image').eq(0),
                        linkHeight = $(el).height(),
                        imageHeight = thumbnail.height();
                        $(el).height(imageHeight);
                });
            });
        });
    }
})(jQuery);
