var featureLinksBox = {
    init: function() {
        this.setFlex();

    },
    setFlex: function() {
        var $featureLinks = $('.feature-links');

        for (var i = 0; i < $featureLinks.length; i += 1) {
            var $featureLinksBoxes = $featureLinks.eq(i).find('.feature-links-box');

            for (var j = 0; j < $featureLinksBoxes.length; j += 2) {
                var $newDiv = $('<div class="flex-wrapper"></div>').insertBefore($featureLinksBoxes.eq(j));
                $featureLinksBoxes.eq(j).appendTo($newDiv);

                if (j + 1 < $featureLinksBoxes.length) { // eslint-disable-line max-depth
                    $featureLinksBoxes.eq(j + 1).appendTo($newDiv);
                }
            }
        }
    }
};

(function() {
    featureLinksBox.init();
}(jQuery));
