/* global YT */
(function ($) {
  if (document.querySelector("iframe[data-youkuid-related], iframe[data-youkuid], a[data-fallback-href]")) {
    const config = {
      settings: {
        youtube:   "iframe[data-youkuid-related][src*=\"youtube.com\"], a[href*=\"youtube.com\"][data-fallback-href]",
        vimeo:     "iframe[data-youkuid-related][src*=\"vimeo.com\"], a[href*=\"vimeo.com\"][data-fallback-href]",
        youku:     "iframe[data-youkuid][src*=\"youku.com\"], embed[data-youkuid][src*=\"youku.com\"]",
        ytID:      "yt",
        viID:      "vi",
        yoID:      "yo",
        videoWrap: "video-container"
      },
      
      // Add uniq ID to iframe
      addID: (source, attr) => {
        if (source.length > 0) {
          Array.from(source).forEach((el, i) => {
            let count = i + 1;
            el.setAttribute("id", attr + "-" + count);
          });
        }
      },
      
      // Create div container outside iframe
      createContainer: (source) => {
        $(() => {
          $(source).filter(":not(a)").filter((index) => {
            // Make sure video container does not already exist
            return (!$(source[index]).parents(`.${config.settings.videoWrap}`).length);
          }).wrap(`<div class="${config.settings.videoWrap}"></div>`);
        });
      },
      
      // Remove video container with iframe tag
      removeContainer: (source) => {
        if (source.parentElement.classList.contains(config.settings.videoWrap)) {
          source.parentElement.remove();
        } else {
          source.parentElement.removeChild(source);
        }
      },
      
      // Hide player (iframe)
      hide: (element) => {
        if (element.length > 0) {
          Array.from(element).forEach(el => {
            el.style.display = "none";
          });
        }
      },
      
      // Show player (iframe)
      show: (element) => {
        element.style.display = "";
      },
      
      // Add ?enablejsapi=1 to the and of YouTube URL
      appendYTjsAPI: (element) => {
        if (element.length > 0) {
          Array.from(element).forEach(el => {
            const attributeType = el.hasAttribute("src") ? "src" : "href";

            let tempAtrr = el.getAttribute(attributeType);
            tempAtrr = `${tempAtrr}?enablejsapi=1`;
            el.setAttribute(attributeType, tempAtrr);
          });
        }
      },
      
      // Check if Youku ID exists required add in matrix to youtube in iframe data-youkuid
      isYoukuID: (element) => {
        if (element.length > 0) {
          Array.from(element).forEach(el => {
            return el.getAttribute("data-youkuid");
          });
        }
      },
      
      // check if exist the same data-youkuid and data-youkuid-related
      check: (element, data) => {
        let temp;
        Array.from(element).forEach(el => {
          if(el.getAttribute(data) === temp){
            let tempatrr = el.getAttribute(data);
            el.setAttribute(data, tempatrr +"1" );
          }
          temp = el.getAttribute(data);
        });
      }
    };

    const yts = document.querySelectorAll(`${config.settings.youtube}`);
    const vis = document.querySelectorAll(`${config.settings.vimeo}`);
    const yos = document.querySelectorAll(`${config.settings.youku}`);
    const ytPlayers = [];

    // API YouTube
    const loadYouTubeAPI = () => {
      var tag = document.createElement("script");
      var firstScriptTag = document.getElementsByTagName("script")[0];
      tag.src = "https://www.youtube.com/iframe_api";
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    };
    
    // Fallback to Youku, function
    const fallbackToYoukuFunction = (el) => {
      var fallbackHref = el.getAttribute("data-fallback-href");
      if (fallbackHref && fallbackHref !== "") { 
        // <a> links that open popups, just replace the href
        el.setAttribute("data-original-href", el.href);
        el.href = fallbackHref;
      } else { 
        // Video container template prints out all to start with, so remove the unnecessary ones
        let tempRelatedYoukuID = el.getAttribute("data-youkuid-related");
        let tempRelatedYouku = document.querySelector(`[data-youkuid='${tempRelatedYoukuID}']`);
        if (el.getAttribute("data-youkuid-related") !== "") {
          config.removeContainer(el);
          config.createContainer(tempRelatedYouku);
          config.show(tempRelatedYouku);
        } else {
          config.removeContainer(el);
          console.log(`YouTube iframe id="${el.getAttribute("id")} Please add Youku id.`);
        }                   
      }
    };

    // Fallback to Youku, replaces video with Youku alternative
    const fallbackToYouku = (els) => {
      if (els.length > 1) {
        Array.from(els).forEach(el => { 
          fallbackToYoukuFunction(el);
        });
      } else {
        fallbackToYoukuFunction(els);
      }
    };
    
    // Load the favicon and run the callback
    const checkIfBlocked = (icoURL, blocked, notBlocked) => {
      // Waiting until the DOMContentReady event has fired will improve performance when YouTube isn't available
      $(() => {
        var icon = new Image();
        icon.addEventListener("load", notBlocked);
        icon.addEventListener("error", blocked);
        icon.src = icoURL;
      });
    };

    // YouTube Error event messages
    const onPlayerErrorMessages = (errorStatus) => {
      let consoleMessage = "";

      switch (errorStatus) {
      case 2:
        consoleMessage = "Invalid video parameter. Check ID has 11 chars and no invalid chars, these include ! or *";
        break;
      case 5:
        consoleMessage = "Cannot play video in a HTML5 player or another HTML5 player error has occurred";
        break;
      case 100:
        consoleMessage = "The video was not found. The video may have been removed or marked as private";
        break;
      case 101:
        consoleMessage = "Video permissions set to deny embedding";
        break;
      default:
        consoleMessage = "Video error occured";
      }
      
      return consoleMessage;
    };
    
    // YouTube Error event
    const onPlayerError = (event) => {
      const errorStatus = event.data;
      const errorTargetID = event.target.h.id;
      const consoleMessage = onPlayerErrorMessages(errorStatus);
      const thisPlayerYT = document.querySelectorAll(`#${errorTargetID}`);

      fallbackToYouku(thisPlayerYT);
      console.log(`YouTube iframe id="${errorTargetID}"\n${consoleMessage}\nError Status = ${errorStatus}`);
    };

    // Init YouTube Players
    window.onYouTubeIframeAPIReady = () => {
      const players = document.querySelectorAll("iframe[data-youkuid-related][src*=\"youtube.com\"]");

      for (var i = 0; i < players.length; i += 1) {
        ytPlayers.push(new YT.Player(players[i].getAttribute("id"), {
          events: {
            "onError": onPlayerError
          },
          playerVars: { 
            "origin": window.location.href
          }
        }));
      }
    };

    // Add ID to YouTube, Vimeo, Youku links
    config.addID(yts, config.settings.ytID);
    config.addID(vis, config.settings.viID);
    config.addID(yos, config.settings.yoID);
    config.check(yts, "data-youkuid-related");
    config.check(vis, "data-youkuid-related");
    config.check(yos, "data-youkuid");
    
    // Hide Youku player
    config.hide(yos);
      
    // If YouTube videos exist
    if (yts.length > 0) {
      const blocked = () => {
        fallbackToYouku(yts);
      };

      const notBlocked = () => {
              // Add api scripts to head
        loadYouTubeAPI();
              // Create container outside youtube iframe tag
        config.createContainer(yts);
              // Add YouTube jsapi to the end of scr link
        config.appendYTjsAPI(yts);
      };

      checkIfBlocked("https://youtube.com/favicon.ico", blocked, notBlocked);
    }
    
    // Check if vimeo video exists
    const checkIfVimeoVideoExists = (url, callback) => {
      $.ajax({
        type:     "GET",
        url:      "https://vimeo.com/api/oembed.json?url=" + encodeURIComponent(url),
        dataType: "json",
        complete: function(xhr) {
          callback(xhr.status);
        } 
      });
    };

    // If Vimeo videos exist
    if (vis.length > 0) {
      const blocked = () => {
        fallbackToYouku(vis);
      };

      const notBlocked = () => {
        // Create container outside Vimeo iframe tag
        config.createContainer(vis);
      };

      checkIfBlocked("https://vimeo.com/favicon.ico", blocked, notBlocked);

      // eslint-disable-next-line max-depth
      for (let i = 0; i < vis.length; i += 1) {
        const videoUrl = vis[i].src || vis[i].href;

        checkIfVimeoVideoExists(
          videoUrl,
          (status) => {
            if (status !== 200) {
              console.log(`Vimeo video ${videoUrl} does not exist`);
              fallbackToYouku(vis[i]);
            }
          }
        );
      }
    }
  }
}(jQuery)); 