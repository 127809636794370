/*global gADataLayerPushEvents */
var DEFAULT_ACCORDION_HEADER_CLASS = 'accTitle',
    DEFAULT_ACCORDION_PANEL_CLASS = 'panel',
    DEFAULT_ACCORDION_TARGET_CLASS = 'accContent',
    LEGACY_ACCORDION_HEADER_CLASS = 'accordion__link',
    LEGACY_ACCORDION_PANEL_CLASS = 'accordion__item',
    LEGACY_ACCORDION_TARGET_CLASS = 'accordion__target',
    initAccordions = function($el, headerClass, panelClass) {
        headerClass = headerClass === undefined ? DEFAULT_ACCORDION_HEADER_CLASS : headerClass;
        panelClass = panelClass === undefined ? DEFAULT_ACCORDION_PANEL_CLASS : panelClass;

        $el.UberAccordion({ // eslint-disable-line new-cap
            buttonClass:          headerClass,
            toggle:               true,
            multiple:             true,
            expandOnFocus:        false,
            preventDefaultButton: true,
            hashChange:           false,
            autoExpand:           function() {
                var $accordion = this.$elem,
                    urlFragment = window.location.hash,
                    firstHeader = $accordion.find('.' + DEFAULT_ACCORDION_HEADER_CLASS).eq(0),
                    isNested = false;
                // Accordion is nested inside another tab or accordion
                if ($accordion.parents('.tabs__tab-content').length
                || $accordion.parents('.' + DEFAULT_ACCORDION_TARGET_CLASS).length) {
                    isNested = true;
                }
                // This accordion has an id matching the URL fragment and is not nested:
                // open matching panel
                if (urlFragment && $accordion.find(urlFragment).length && !isNested) {
                    return urlFragment;
                }
                // Check if the first panel should be contracted
                if (firstHeader.data('expanded-by-default') === false) {
                   return '';
                }
                // Fallback: open the first panel
                return '#' + firstHeader.attr('id');
            },
            // GA helper for dataLayer push accordion open/close events
            onSelect: function($button) {
                var btnClassList = $button[0].className,
                    accordionItemHeaderText = $button[0].innerText,
                    pluginActiveClass = this._defaults.appliedClasses.activeButton,
                    gaData = {},
                    //Test if the accordion has been opened or closed
                    accordionOpen = RegExp('\\b' + pluginActiveClass + '\\b').test(btnClassList),
                    accordionState = accordionOpen ? 'open' : 'closed';

                gaData = {
                    'event': 'Accordion ' + accordionState,
                    'title': accordionItemHeaderText
                };

                gADataLayerPushEvents(gaData);
            }
        });

    },
    handleLegacyAccordions = {
        init: function($accordionEl) {
            var regEx = new RegExp('=', 'g'),
                thisObj = this;

            $accordionEl.attr({
                multiselectable: true,
                role:            'tablist'
            });

            //Iterate through the accordion panels
            $accordionEl.find('.' + LEGACY_ACCORDION_PANEL_CLASS).each(function() {
                var $thisPanel = $(this),
                    href = $thisPanel.find('.' + LEGACY_ACCORDION_HEADER_CLASS).attr('href'),
                    newID = window.btoa(href).replace(regEx, ''); //Avoid having duplicated ids

                //Format Accordion header
                thisObj.formatHeader($thisPanel, newID);
                //Format Accordion content
                thisObj.formatContent($thisPanel, newID);
            });

            //Init the accordion
            initAccordions($accordionEl, DEFAULT_ACCORDION_HEADER_CLASS, LEGACY_ACCORDION_PANEL_CLASS);
        },
        formatHeader: function(panel, referenceID){
            /**
                Modify the accordion header:
                    - Remove the legacy class from the <a>
                    - Apply the default class to the parent
                    - Apply required ARIA attributes to make the plugin work
            **/
            panel.find('.' + LEGACY_ACCORDION_HEADER_CLASS)
                .removeClass(LEGACY_ACCORDION_HEADER_CLASS)
                .parent().addClass(DEFAULT_ACCORDION_HEADER_CLASS);

            panel.find('.' + DEFAULT_ACCORDION_HEADER_CLASS).attr({
                id:              referenceID,
                'aria-controls': referenceID,
                role:            'tab',
                'aria-expanded': false,
            });
        },
        formatContent: function(panel, referenceID){
            /**
                Modify the accordion target content wrapper
                    - Toggle legacy class with the default one
                    - Aply required ARIA attributes to make the plugin work
            **/
            panel.find('.' + LEGACY_ACCORDION_TARGET_CLASS)
                .removeClass(LEGACY_ACCORDION_TARGET_CLASS)
                .addClass(DEFAULT_ACCORDION_TARGET_CLASS)
                .attr({
                    'aria-labelledby': referenceID,
                    'aria-hidden':     true,
                    role:              'tabpanel'
                });
        }
    };

/**
    We have to wait for the load event because we're calling the global function 'gADataLayerPushEvents'.
    This function has been defined in modules/global/js and is out of the document ready or window.load,
    so it exists globally. However, when it gets called in the squiz-module-accordion, it is in fact getting
    called in the plugins.js file (after compiling), which is loaded in the DOM before the global.js
    (where the function exists).
**/
function hashToExpand($, hash, $el) {
    var parentHash, atThisLevel;
    if ($el.find(hash).length > 0) {
        atThisLevel = Boolean($el.find('.tabs__tab-content').first().find('> .tabs__target'+hash).length);
        if (atThisLevel) { return hash; }
        parentHash = '#' + ($(hash).parents('.tabs__target').attr('id') || hash.replace(/^#/, ''));
        return parentHash;
    }
    return $el.find('.tabs__link').first().attr('href') || ''; //Or case handles tab boxes with no tabs
}

$(window).load(function(){
    var tabsSettings = {
        buttonClass: 'tabs__link',
        autoExpand:  function() {
            return hashToExpand(jQuery, window.location.hash, this.$elem);
        },
        toggle:                 false,
        multiple:               false,
        //preventDefaultButton: false, // this is overriden with the setting below
        useHistoryReplaceState: true,
        //GA helper for dataLayer push tab open event
        onSelect:               function($button) {
            gADataLayerPushEvents({
                'event': 'Tab open',
                'title': $button[0].innerText
            });

            //Auto expand nested tabs when a parent tab is selected
            if (window.location.hash.indexOf(',') === -1) { // <-- Related to MS-52 - check ticket for more details
                var $tabEl = $button;
                setTimeout(function() {
                    if ($($tabEl.attr('href')).find('.tabs__target:visible').length === 0) {
                        $($tabEl.attr('href')).find('.tabs__link').eq(0).click();
                    }
                }, 1);
            }
        }
    };

    // Init tabs
    $('.tabs').not('.tabs--nested').UberAccordion(tabsSettings); // eslint-disable-line new-cap
    $('.tabs--nested').UberAccordion(tabsSettings); // eslint-disable-line new-cap

    // Init accordions
    $('.accordion').each(function(){
        var $thisAccordion = $(this);

        if (!$thisAccordion.find('.' + DEFAULT_ACCORDION_HEADER_CLASS).length ||
            !$thisAccordion.find('.' + DEFAULT_ACCORDION_PANEL_CLASS).length) {
            //Legacy accordion markup fallback
            handleLegacyAccordions.init($thisAccordion);
        } else {
            //Regular accordion markup structure
            initAccordions($thisAccordion, DEFAULT_ACCORDION_HEADER_CLASS, DEFAULT_ACCORDION_PANEL_CLASS);
        }
    });
});
