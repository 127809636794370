function initPopupVideos(els) {
    els.magnificPopup({
        delegate: 'a:not(.edit-btn)',
        type:     'iframe',
        iframe:   {
            patterns: {
                youtube_short: { // eslint-disable-line camelcase
                    index: 'youtube.com/',
                    id: function(src) {
                        // link pattern
                        var mt = src.match(/v=([^&]+)(&(.*))?/);
                        
                        if (mt) {
                            return mt[1] +'?'+ mt[3];
                        }

                        // embed pattern
                        mt = src.match(/embed\/([^\?\/]+)(\?(.*))?/);
                        if (mt) {
                            return mt[1] +'?'+ mt[3];
                        }
                    },
                    src: '//www.youtube.com/embed/%id%&autoplay=1&rel=0' 
                },
                youtube: {
                    index: 'youtu.be/',
                    id:    'youtu.be/',
                    src:   '//www.youtube.com/embed/%id%?autoplay=1'
                }
            }
        },
        disableOn: function () {
            if ($(window).width() < 768) {
                return false;
            }
            return true;
        },
        callbacks: {
            open: function() {
                $('html').css('margin-right', '0');
            }
        }
    });
}

(function ($) {
    'use strict';

    var $slideshowGallery = $('.gallery-slick-slideshow');

    if ($slideshowGallery.length) {
        $slideshowGallery.slick({
            dots:           false,
            arrows:         true,
            speed:          400,
            slide:          '.gallery-slick-slideshow__slide',
            slidesToScroll: 1,
            slidesToShow:   2,
            prevArrow:      '<button type="button" class="slick-prev"><span>Prev</span></button>',
            nextArrow:      '<button type="button" class="slick-next"><span>Next</span></button>',
            infinite:       false,
            responsive:     [
                {
                    breakpoint: 768,
                    settings:   {
                        adaptiveHeight: true,
                        slidesToShow:   1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }

    $('.popup__gallery').magnificPopup({
        delegate:    'a',
        type:        'image',
        closeMarkup: '<button title="%title%" type="button" class="mfp-close" aria-label="close">&times;</button>',
        gallery:     {
            enabled: true
        },
        image: {
            titleSrc: 'title'
        },
        callbacks: {
            open: function() {
                $('html').css('margin-right', '0');
            }
        }
    });

    initPopupVideos($('.popup__video'));

}(jQuery));
