$('.typeahead').each(function(){
    var stocks = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('assetname'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        //prefetch: 'http://mon-dev.clients.squiz.net/monash/search-panels/search-panel-json-w-assetid'
        prefetch: $(this).data('json-asset-listing') + '?id=' + $(this).data('search-panel-source') 
    });

    $(this).typeahead(
        null, {
        name: 'stocks',
        displayKey: 'assetname',
        highlight: 'true',
        minLength: 0,
        source: stocks
    }).on({
        'typeahead:autocomplete': function(event, data){
            afterTypeahead(data, $(this));
        },
        'typeahead:select': function(event, data){
            afterTypeahead(data, $(this));
        }
    });
});

function afterTypeahead(data, thisObj){
    // $.get('http://mon-dev.clients.squiz.net/monash/search-panels/get-asset-meta-entry-requirements?id=' + data.assetid, function( data ) {

    $.get($(thisObj).data('entry-requirements-source') + '?id=' + data.assetid, function(dataBlob) {
        $(thisObj).parents('.monash-search-panel').find('.section6__loader').html(dataBlob);
    });
}
