// (function($) {

//     var mqWide = '(min-width: 60em)',
//         mqListener = window.matchMedia(mqWide);

//     /* Apply masonry at ≥ wide screen size. */
//     function handleSocialFeedListing(mql) {
//         var $listing = $('.social-media-feed-listing');
//         if (mql.matches) {
//             // Init masonry (after images loaded)
//             $listing.imagesLoaded({}, function() {
//                 $listing.masonry({
//                     itemSelector: '.social-media-feed-box__wrapper'
//                 });
//             });
//         }
//         else {
//             // Destroy masonry
//             $listing.masonry().masonry('destroy');
//         }
//     }

//     mqListener.addListener(handleSocialFeedListing);
//     handleSocialFeedListing(mqListener);

// }(jQuery));
