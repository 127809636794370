(function ($) {
  function customSelect(options) {
    function __createLabelSelectric(currItem) {
      var regex = new RegExp(/\(.*?\)/, "gi");
      var val =
        currItem.text.match(regex) && currItem.text.match(regex).length > 0
          ? currItem.text.match(regex)[0]
          : "";
      var text = currItem.text.split(val).join("").trim();
      var label =
        "<strong>" +
        text +
        "</strong> " +
        "<span class='results'>" +
        val +
        "</span>";
      return label;
    }

    function __updateURLSelectric(element) {
      var path = $(element).val();
      return (window.location = path);
    }

    var settings = {
      selectorName: options.el.replace(/(.)|(#)/, ""),
      el: $("select" + options.el),
      mobileBreakPoint:
        options.mobileBreakPoint !== undefined ? options.mobileBreakPoint : 960,
      selectricOptions: {
        disableOnMobile:
          options.disableOnMobile !== undefined
            ? options.disableOnMobile
            : false,
        nativeOnMobile:
          options.nativeOnMobile !== undefined ? options.nativeOnMobile : false,
        labelBuilder: function (currItem) {
          return __createLabelSelectric(currItem);
        },
        optionsItemBuilder: function (itemData) {
          return __createLabelSelectric(itemData);
        },
        onChange: function (element) {
          __updateURLSelectric(element);
        },
      },
    };

    function __selectOption() {
      var url = window.location.search;
      var selectOptions = settings.el.find("option");
      selectOptions.each(function () {
        var val = $(this).val();
        if (
          ((val.indexOf(url) !== -1 && url.indexOf(val) !== -1) ||
            val.indexOf("f.Tabs%7C") === -1) &&
          val
        ) {
          $(this).attr("selected", "selected");
        }
      });
    }

    function __widthChange(mq) {
      if (mq.matches) {
        if ($(".selectric-" + settings.selectorName).length > 0) {
          settings.el.selectric("destroy");
        }
      } else {
        settings.el.selectric(settings.selectricOptions);
      }
    }

    function __initSelectric() {
      if (window.matchMedia) {
        var mobileBreakPoint = window.matchMedia(
          "(min-width:" + settings.mobileBreakPoint + "px)"
        );

        mobileBreakPoint.addListener(__widthChange);
        __widthChange(mobileBreakPoint);
      }
    }

    var init = function () {
      settings.el.hide();
      __selectOption();
      __initSelectric();
    };

    return Object.freeze({
      init: init,
    });
  }
  var selectTabSearchFilter = customSelect({ el: ".search-tab__select" });
  selectTabSearchFilter.init();
})(jQuery);
