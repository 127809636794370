(function($) {
    // Fix for checkboxes/radio buttons causing the page to scroll up when selected/unselected
    // Note: only noticeable in chrome
    $('form[id*="page_asset_builder_"] div[id*="_calendar_event_single"] label[for]').on('click', function (e) {
        if (this.getAttribute('for').indexOf('calendar_event') === -1) {
            var target = window[this.htmlFor];
            target.checked = !target.checked;
            e.preventDefault();
        }
    });

}(jQuery));
