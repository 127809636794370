// events-only - event_period=<dropdown val>
$(document).ready(function () {
    'use strict';

    var $this = $(this),
            $eventDropdown = $('#event-period'),
            $browseButton = $('.search-box--news-events-browse-lhs'),
            $label = $('.selectric-search-box--news-events__select span.label');

            $browseButton.on('click', function(event) {
                event.preventDefault();

                var $form = $(this).parents('.search-box--news__form');
                var eventVal = $eventDropdown.val();
                var eventSelectedText = $eventDropdown.find('option:selected').text();

                //handles onclick url change
                //location.href = '?&event_period=' + eventVal;
                //console.log($form.html());
                $form.submit();
            });
}(jQuery));