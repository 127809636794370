// Handle validation
var formElementsObj = {
  init: function() {
    this.buildCustomMessages();
  },
  buildCustomMessages: function() {
    var validationMessages = $("[data-validation-msg]")
      .toArray()
      .reduce(function(obj, inpt) {
        var $inpt = $(inpt);
        obj[$inpt.attr("name")] = $inpt.attr("data-validation-msg");
        return obj;
      }, {});

    //Init the validation plugin with our custom messages
    this.initValidationPlugin(validationMessages);
  },
  initValidationPlugin: function(messagesObj) {
    var getContainerForInput = function(elem) {
      var $container = $(elem).parents(".sq-form-question");
      return $container;
    };
    var options = {
      errorClass: "sq-form-error",
      errorPlacement: function(error, element) {
        var $container = $(element).parents(".sq-form-question-answer");
        if ($(element).attr("id") === "recaptcha_response_field") {
          error.insertBefore(element);
        } else {
          $container.prepend(error);
        }
      },

      // fn((element, errorClass, validClass))
      highlight: function(element) {
        getContainerForInput(element).addClass("sq-form-question-error");
      },

      // fn element, errorClass, validClass)
      unhighlight: function(element) {
        getContainerForInput(element).removeClass("sq-form-question-error");
      },
      messages: messagesObj
    };

    $(".sq-form").validate(options);
  }
};

/* ========================================================
 * Custom file input styles
 * ========================================================
 *
 * Applies a custom style to file input elements by hiding
 * the native element and injecting a styled façade element
 * above the original file input.
 * ======================================================== */
var customFileInputs = (function() {
  return {
    init: function() {
      var fileInputs, placeholderText, facade;

      // Original file input elements
      fileInputs = $('input[type="file"]');

      // Placeholder text that appears when empty input is initially loaded
      placeholderText = "Choose file…";

      // Façade overlay element that will have custom styles applied
      facade =
        '<span title="" class="sq-form-question-facade is-empty">' +
        placeholderText +
        "</span>";

      fileInputs.each(function(i, el) {
        var fileInputID = el.id,
          facadeOverlay;

        // Generate empty façade element with custom styles applied
        facadeOverlay = $(facade).insertAfter(el);

        // Has ID - add aria markup
        if (typeof fileInputID !== "undefined" && fileInputID) {
          facadeOverlay.attr("aria-controls", fileInputID);
        }

        // Façade element activates corresponding file input
        facadeOverlay.on("touchstart click", function(ev) {
          $(ev.currentTarget)
            .prev('input[type="file"]')
            .trigger("click");
        });

        // When value of selected file changes, print the value to the façade element
        $(el).on("change", function(ev) {
          var fileInput = $(ev.currentTarget),
            filePath = fileInput.val(),
            facade = fileInput.next(".sq-form-question-facade");

          // File path is not empty
          if (filePath.trim()) {
            facade.removeClass("is-empty");
            facade.text(filePath);
          }
          // File path is empty
          else {
            facade.addClass("is-empty");
            facade.text(placeholderText);
          }

          // Apply title (to display full path and file name as tooltip)
          facade.attr("title", fileInput.val());

          // Grab focus to apply appropriate styles
          fileInput.focus();
        });
      });
    }
  };
})();

(function($) {
  // Form validation
  formElementsObj.init();

  // Custom file input styling
  customFileInputs.init();

  // Custom select field styling
  $("select:not(.selectric-disabled, .search-tab__select)").selectric();
  // Fix for accessibility on Selectric
  $(".selectric-input").attr("title", "Select a value");
})(jQuery);
