function afterSelectbox(thisObj){
    var getEntryMetadata = $(thisObj).data('get-entry-metadata') + '?id=' + $(thisObj).find(':selected').data('entry-requirements-source');

    $.get(getEntryMetadata, function(dataBlob) {
        $(thisObj).parents('.dropdown-container-wrapper').find('.section6__loader').html(dataBlob);
    });
}

$('.dropdown-select').each(function(){
    $(this).selectric().on('change', function() {
        afterSelectbox($(this));        
    });
});
