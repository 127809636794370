(function () {
    'use strict';

    var $list = $('.js-search-box--browse__list'),
        $label = $('.js-search-box--browse__label'),
        $browseButton = $('.js-search-box__submit--browse'),
        $searchButton = $('.js-search-box__submit--search'),
        classOpened = 'search-box--browse__list--opened',
        classWrapper = 'search-box--browse__wrapper';

    // Open the list of categories on label click
    $label.on('click', function (e) {
        e.preventDefault();
        $list.toggleClass(classOpened);
    });

    // Close the list of categories if clicked anywhere outside the form
    $(document).on('click', function (e) {
        var $clicked = $(e.target);
        if (!$clicked.parents().hasClass(classWrapper)) {
            $list.removeClass(classOpened);
        }
    });

    function handleCategorySelection() {
        var $checkboxes = $list.find('input[type="checkbox"]'),
            // a list of <span> populated with checked inputs' labels
            checkedList = $.map($checkboxes.filter(':checked'), function(element) {
                var text = $(element).next('label').text();
                return '<span title="' + text + '">' + text +'</span>';
            });

        // remove all currently displayed categories
        if ($label.length > 0) { $label.find('span').remove(); };

        // if everything or nothing is selected, print 'All categories'
        if (checkedList.length === 0 || checkedList.length === $checkboxes.length) {
            $label.append('<span title="All categories">All categories</span>');
        } else {
            // otherwise, list selected categories
            $label.append(checkedList.join('<span>, </span>'));
        }
    }

    if ($list.length > 0) {
        handleCategorySelection();
        $list.find('input').on('change', function() {
            handleCategorySelection();
        });
    }

    // Browse Category
    // On click, everything from the current input from Search panel will be removed/reset
    // Then querystring will be added to the current URL to filter listing results
    // Requirements: input need to have data-facetparamname set to the facet's param Name
    // e.g.
    // <input type="checkbox" class="sq-form-field" id="information+technology"
    //     data-facetparamname="f.News+Categories%7C2" value="information+technology" />
    $browseButton.on('click', function(e){
        e.stopPropagation(); e.preventDefault();
        var $checkboxes = $list.find('input[type="checkbox"]'),
            checkedList = $.map($checkboxes.filter(':checked'), function(element) {
                var $element = $(element),
                    text = $element.data('facetparamname') + '=' + $element.val();
                return text;
            });
        // console.log($checkboxes, checkedList);
        if ($checkboxes.length === checkedList.length) {
            location.href = location.href.split('?')[0];
        } else {
            var query = checkedList.join('&');
            location.href = location.href.split('?')[0] + '?' + query;
        }
    });

    /* eslint-disable max-statements */
    // Search News
    // On click, 'Search' button will reset all the Categories values
    // Then submit current search form, including 'query', 'year' and 'month'
    // Date range will also be calculated based on 'year' and 'month' values
    // and put into hidden fields 'meta_d3' & 'meta_d4'
    $searchButton.on('click', function(e){
        e.stopPropagation(); e.preventDefault();
        var $this = $(this),
            $dateMin = $('#search-news-date-min'),
            $dateMax = $('#search-news-date-max'),
            months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            metaMonth = $('#search-news-month').val(),
            metaYear = $('#search-news-year').val(),
            $form = $this.parents('.search-box--news__form');

        if (metaMonth.length === 0) {
            // if only 'year' is set, date range is for the whole year
            if (metaYear.length !== 0) {
                $dateMin.val('1Jan' + metaYear); $dateMax.val('31Dec' + metaYear);
            } else {
                // if nothing set, remove date range
                $dateMin.val(''); $dateMax.val('');
            }
        } else {
            var monthInt = parseInt(metaMonth, 10),
                lastDay;

            // if both 'month' and 'year' is set, date range is the whole month
            if (metaYear.length !== 0) {
                lastDay = new Date(parseInt(metaYear, 10), monthInt + 1, 0).getDate();
                $dateMin.val('1' + months[monthInt] + metaYear);
                $dateMax.val('' + lastDay + months[monthInt] + metaYear);
            } else {
                // if only 'month' is set, use current year
                var year = new Date().getFullYear();
                lastDay = new Date(year, monthInt + 1, 0).getDate();
                $dateMin.val('1' + months[monthInt] + year);
                $dateMax.val('' + lastDay + months[monthInt] + year);
            }
        }

        $form.submit();
    });
    /* eslint-enable max-statements */

    // Populate select inputs have attribute data-val set
    var $selectsWithVal = $('select[data-val!=""]');
    $selectsWithVal.each(function() {
        var $this = $(this),
            $options = $this.find('option'),
            selectedValue = '' + $this.data('val');

        for (var i=0; i < $options.length; i+=1) {
            if (('' + $($options[i]).attr('value')) === selectedValue) {
                $this.prop('selectedIndex', i).selectric('refresh');
                return;
            }
        }
    });

    //  Browse Category Control
    var $checkboxes = $list.find('input[type="checkbox"]');
        // Clear all
    var $clearAll = $list.find('.js-clear-all');
    $clearAll.on('click', function() {
        $checkboxes.prop('checked',false);
        handleCategorySelection();
    });

        // Select all
    var $selectAll = $list.find('.js-select-all');
    $selectAll.on('click',function() {
        $checkboxes.prop('checked',true);
        handleCategorySelection();
    });
}(jQuery));
