$('.skip-links__link').keydown(function (e) {
    var $obj = $(this);
    
    if (e.which === 13) {
        e.preventDefault();

        // wait until skip link hides itself to prevent offset
        setTimeout(function () {
            $('html, body').animate({
                scrollTop: $($obj.attr('href')).offset().top
            }, 0);
        }, 10);
        
        $($obj.attr('href')).find('a:visible:first').focus();
    }
});

// handle skip-links aria-hidden attribute
$('.skip-links__link')
    .focusin(function () {
        $(this).parents('.skip-links').attr('aria-hidden', 'false');
    })
    .focusout(function () {
        $(this).parents('.skip-links').attr('aria-hidden', 'true');
    });