function initTimelineBrowseCategories() {
    var $listCats = $('.js-timeline-search-box--browse__list'),
        $labelCats = $('.js-timeline-search-box--browse__label'),
        $browseBtn = $('.js-timeline-search-box__submit--browse'),
        classOpen = 'search-box--browse__list--opened',
        classWrap = 'search-box--browse__wrapper';

    // Open the list of categories on label click
    $labelCats.on('click', function (e) {
        e.preventDefault();
        $listCats.toggleClass(classOpen);
    });

    // Close the list of categories if clicked anywhere outside the form
    $(document).on('click', function (e) {
        var $clicked = $(e.target);
        if (!$clicked.parents().hasClass(classWrap)) {
            $listCats.removeClass(classOpen);
        }
    });

    function handleCategSelection() {
        var $checkboxes = $listCats.find('input[type="checkbox"]'),
            // a list of <span> populated with checked inputs' labels
            checkedList = $.map($checkboxes.filter(':checked'), function(element) {
                var text = $(element).next('label').text();
                return '<span title="' + text + '">' + text +'</span>';
            });

        // remove all currently displayed categories
        if ($labelCats.length > 0) { $labelCats.find('span').remove(); };

        // if everything or nothing is selected, print 'All categories'
        if (checkedList.length === 0 || checkedList.length === $checkboxes.length) {
            $labelCats.append('<span title="All categories">All categories</span>');
        } else {
            // otherwise, list selected categories
            $labelCats.append(checkedList.join('<span>, </span>'));
        }
    }

    if ($listCats.length > 0) {
        handleCategSelection();
        $listCats.find('input').on('change', function() {
            handleCategSelection();
        });
    }

    // Browse Category
    // On click, everything from the current input from Search panel will be removed/reset
    // Then querystring will be added to the current URL to filter listing results
    // Requirements: input need to have data-facetparamname set to the facet's param Name
    // e.g.
    // <input type="checkbox" class="sq-form-field" id="information+technology"
    //     data-facetparamname="f.News+Categories%7C2" value="information+technology" />
    $browseBtn.on('click', function(e){
        e.stopPropagation(); e.preventDefault();
        var $checkboxes = $listCats.find('input[type="checkbox"]'),
            checkedList = $.map($checkboxes.filter(':checked'), function(element) {
                var $element = $(element),
                    text = $element.data('facetparamname') + '=' + $element.val();
                return text;
            });

        var query = checkedList.join('&');

        const redirectTo = $browseBtn.attr('data-redirect-to');
        // If this attribute exists, it means that the browse/search fields were loaded through ajax
        // in the timeline landing page. So we don't want to just append the QS parameters to the current URL.
        // We want to redirect to the search page and append the QS parameters.
        if (redirectTo) {
            location.href = redirectTo + '?' + query;
        } else {
            if ($checkboxes.length === checkedList.length) {
                location.href = location.href.split('?')[0];
            } else {
                location.href = location.href.split('?')[0] + '?' + query;
            }
        }
    });


    // Populate select inputs have attribute data-val set
    var $selectsWithVal = $('select[data-val!=""]');
    $selectsWithVal.each(function() {
        var $this = $(this),
            $options = $this.find('option'),
            selectedValue = '' + $this.data('val');

        for (var i=0; i < $options.length; i+=1) {
            if (('' + $($options[i]).attr('value')) === selectedValue) {
                $this.prop('selectedIndex', i).selectric('refresh');
                return;
            }
        }
    });

    //  Browse Category Control
    var $checkboxes = $listCats.find('input[type="checkbox"]');
        // Clear all
    var $clearAll = $listCats.find('.js-clear-all');
    $clearAll.on('click', function() {
        $checkboxes.prop('checked',false);
        handleCategSelection();
    });

        // Select all
    var $selectAll = $listCats.find('.js-select-all');
    $selectAll.on('click',function() {
        $checkboxes.prop('checked',true);
        handleCategSelection();
    });
}

(function () {
    'use strict';
    initTimelineBrowseCategories();

}(jQuery));
