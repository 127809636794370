/*global boxVideosInit*/

var $rhsEvents = $('.box-listing-element--events'),
    viewMoreBtnClass = 'js-rhs-events__view-more',
    //To be removed from the markup once the Events search page gets impplemented
    showAllEventsTmpClass = 'js-show-all-events-on-mobile',
    viewMoreHiddenClass = 'rhs-events__view-more--hidden',
    rhsEventsCtrl = {
        init: function(){
            if ($rhsEvents.length) {
                this.handleMobileViewMore();
            }
        },
        handleMobileViewMore: function(){
            //Handle View More button click
            $rhsEvents.on('click', '.' + viewMoreBtnClass, function(e) {
                /**
                    The functionality of showing all the events on mobile through the btn click has been added
                    as a temporary measure while the Events search page is not yet implemented. Removing the
                    class 'js-show-all-events-on-mobile' in the markup will make the button to have the regular
                    behaviour.
                **/
                if ($rhsEvents.hasClass(showAllEventsTmpClass)) {
                    e.preventDefault();
                    //Hide the View More button
                    $(this).slideUp('fast');
                    //Keep standard padding to the footer
                    $rhsEvents.addClass(viewMoreHiddenClass);
                    //Iterate over the events and make them visible
                    $rhsEvents.find('.box-listing-element__events-item').each(function(key, val) {
                        //Only worth to manipulate the n+5 elements
                        if (key > 3) {
                            $(val).slideDown('fast');
                        }
                    });
                }
            });
        }
    },
    rhsLegacyBoxesCtrl = {
        init: function(){
            var legVideoBoxes = $('.js-legacy-sidebar-box .video.mediaLightbox').parents('.js-legacy-sidebar-box');

            //Handle standard youtube videos, i.e. regular links, not iFrames
            this.youtubeHandlerStandard(legVideoBoxes);

            //Init Magnific popup handler for the new DOM manipulated elements
            boxVideosInit();

            //Reposition hardcoded iFrames markup in the DOM
            $('.js-legacy-sidebar-box iframe').each(function(){
                $(this).prependTo($(this).parents('.js-legacy-sidebar-box'));
            });

            //Reposition Thumbnails for non video boxes
            this.repositionThumbnails();
        },
        youtubeHandlerStandard: function(videoBoxes){
            var controler = this;
            videoBoxes.each(function(){
                var $thisBox = $(this),
                    $videoEl = $thisBox.find('.video.mediaLightbox');
                $videoEl
                    .prependTo($thisBox)
                    .addClass('js-listing-box-video-link box-listing-element__video-thumb-link')
                    .data('video-id', controler.getYoutubeID($videoEl.attr('href')))
                    .find('img')
                        .addClass('box-listing-element__video-thumb-image');
            });

        },
        getYoutubeID: function(url) {
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/,
                match = url.match(regExp);
            return (match && match[7].length === 11) ? match[7] : false;
        },
        repositionThumbnails: function() {
            $('.js-legacy-sidebar-box .panel-summary img').each(function(){
                var $thisEl = $(this),
                    $thisElParent = $thisEl.closest('.js-legacy-sidebar-box'),
                    linkURL;

                $thisEl
                    .addClass('box-listing-element__thumb-image')
                    .prependTo($thisElParent);

                if ($thisElParent.find('a').length) {
                    linkURL = $thisElParent.find('a').attr('href');
                    $thisEl.wrapAll('<a class="box-listing-element__thumb-link" href="' + linkURL + '"></a>');
                }

            });
        }
    };

(function() {
    rhsEventsCtrl.init();
    rhsLegacyBoxesCtrl.init();
}(jQuery));
