(function () {
    const landingSearchBoxDest = document.querySelector('.js-timeline-landing-search-ajax');

    if (landingSearchBoxDest) {
        const searchBoxContentURL = landingSearchBoxDest.getAttribute('data-ajax-landing-search-url');

        $.ajax({
            url:  searchBoxContentURL,
            type: 'get',
            data: {
                searchboxonly: true,
                query:        '!null'
            },
            success: response => {
                const searchBrowseForm = $(response).find('#search-news');
                // Inject the search/browse into the DOM
                $(landingSearchBoxDest).append(searchBrowseForm.html());
                // Init the browse categories builder mechanism
                initTimelineBrowseCategories();
            }
        });
    }
}());
