function detectIE() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}

var objectFit = function(selector, settings) {
    if ($(selector).length && !Modernizr.objectfit) {

        var $this = $(selector),
            objs = [], // eslint-disable-line no-unused-vars
            settings = {
                display:      undefined === settings.display ? 'block' : settings.display,
                position:     undefined === settings.position ? 'center center' : settings.position,
                adjustHeight: undefined === settings.adjustHeight ? false : settings.adjustHeight
            };

        // Init
        objs = $this.toArray().map(function(el, i){ // eslint-disable-line no-unused-vars

            // adjust height if required
            if (settings.adjustHeight) {
                $(selector).hide();
                $(el).parent().css('height','');
                $(el).parent().css('height',$(el).height());
                $(selector).show();
            }

            // set the bg image
            $(el).css('opacity',0);
            $(el).parent().css({
                'background-image':    'url('+$(el).attr('src')+')',
                'background-size':     'cover',
                'background-position': settings.position,
                'display':             settings.display
            });

        });
    }
};

const cardsOrganiser = {
    settings: {
        desktopBP:  '60em',
        selectors: '.timeline-card__background--50, .timeline-card__background--75, .js-timeline-card__video'
    },
    arrange: () => {
        cardsOrganiser.initBreakpoints();
    },
    initBreakpoints: () => {
        const _this = cardsOrganiser;
        const bp = `(min-width: ${_this.settings.desktopBP})`;
        const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent && !navigator.userAgent.match('CriOS');
        let  count = 0; // Used as workaround of a Safari bug firing the code below twice when resizing from mobile to desktop

        window.matchMedia(bp).addListener(function(mql) {
            // Validate safari user agent. Safari has a bug that needs to be handled as a snowflake
            if (isSafari) {
                count += 1;
                if (count > 1) {
                    // Safari bug. Event being fired for the 2nd time. Let's not execute anything. Resetting the counter.
                    count = 0;
                    if (!mql.matches) {
                        _this.moveEls(!mql.matches);
                    }
                } else {
                    // 1st time the event was fired. Let's execute the function
                    _this.moveEls(!mql.matches);
                }
            } else {
                _this.moveEls(!mql.matches);
            }
        });

        if (!window.matchMedia(bp).matches) {
            _this.moveEls(true);
        }
    },
    moveEls: mobileFlag => {
        const _this = cardsOrganiser;
        const arrEls = Array.from(document.querySelectorAll(_this.settings.selectors));
        const mappedOrigsDests = _this.mapOrigsNDests(arrEls, mobileFlag);

        arrEls.forEach(el => {
            el.parentNode.classList.toggle('timeline-card--arranged');
        });
        mappedOrigsDests.forEach(el => {
            el.forEach(obj => {
                // Replace the target's HTML with the source's HTML
                obj.dest.innerHTML = obj.origin.innerHTML;
                // Clear the source's HTML
                obj.origin.innerHTML = '';
                // Copy the classes applied on the source to the target, ensuring cell specific styles to still be applied
                obj.dest.setAttribute('class', obj.destClasses);
                // Remove inline styles so it doesn't override the classes
                obj.dest.setAttribute('style', '');
                // Set a transparent background colour in the source so the background is visible
                if (!(obj.videoFlag && !mobileFlag)) {
                    $(obj.origin).css('background-color', 'transparent');
                }

                if (obj.bckgndEl) {
                    // toggle a modifier class to the banner
                    if (obj.bckgndEl.classList.contains(obj.classToApply)) {
                        obj.bckgndEl.classList.remove(obj.classToApply);
                    } else {
                        obj.bckgndEl.classList.add(obj.classToApply);
                    }
                }
            });
        });
    },
    mapOrigsNDests: (arrEls, mobileFlag) => {
        const _this = cardsOrganiser;
        // Iterate over the banners array
        return arrEls.map(el => {
            let wrapper = el.classList.contains('js-timeline-card__video') ? el : el.nextElementSibling;
            let videoFlag = el.classList.contains('js-timeline-card__video') ? true : false;
            // [X, Y, Z]: X = source; Y = Target; Z = Element from where to copy classes to apply on the target
            // Array with coordinates for the 1st element to be affected.
            const arrArgs1 = mobileFlag ? videoFlag ? [21, 12, 21] : [21, 22, 21] : videoFlag ? [21, 11, 21] : [21, 11, 21];
            // Array with coordinates for the 2nd element to be affected.
            const arrArgs2 = mobileFlag ? videoFlag ? [11, 21, 11] : [11, 21, 11] : videoFlag ? [12, 21, 12] : [22, 21, 22];
            // Return an array of objects, each containing a mapping between source and target so we can move the cells around
            return _this.getMappedObsArr(arrArgs1, arrArgs2, wrapper, el, videoFlag);
        });
    },
    getMappedObsArr: (arrArgs1, arrArgs2, wrapper, bckgndEl, videoFlag) => {
        return [
            {
                origin:       wrapper.querySelector(`[data-col="${arrArgs1[0]}"]`),
                dest:         wrapper.querySelector(`[data-col="${arrArgs1[1]}"]`),
                destClasses:  wrapper.querySelector(`[data-col="${arrArgs1[2]}"]`).getAttribute('class'),
                bckgndEl:     bckgndEl,
                classToApply: 'timeline-card__background--arranged',
                videoFlag:    videoFlag
            },
            {
                origin:       wrapper.querySelector(`[data-col="${arrArgs2[0]}"]`),
                dest:         wrapper.querySelector(`[data-col="${arrArgs2[1]}"]`),
                destClasses:  wrapper.querySelector(`[data-col="${arrArgs2[2]}"]`).getAttribute('class'),
                videoFlag:    videoFlag
            }
        ];
    }
};
