var $lhsNav = $('.lhs-nav');

var lhsNavObj = {
    init: function () {
        var thisObj = this;
        thisObj.addExpandArrows();
        thisObj.setEventHandlers();
        thisObj.autoExpandCurrent();
    },
    addExpandArrows: function () {
        var toggleButtonSource =
            '<button type="button" title="Expand/Collapse" class="lhs-nav-list__item-cta"></button>';
        $lhsNav.find('.js-site-section-nav-item').each(function (i, el) {
            var navItem = $(el),
                navLink = navItem.children('.js-site-section-nav-link');
            if (navItem.children('.lhs-nav-list').length) {
                navLink.find('.lhs-nav-list__item-cta').remove();
                navLink.append(toggleButtonSource);
            }
        });
    },
    setEventHandlers: function () {
        var thisObj = this;
        $lhsNav.on('click', '.lhs-nav-list__item-cta', thisObj.handleListItemExpand);
    },
    handleListItemExpand: function (event) {
        var toggleButton = $(event.currentTarget),
            childLists = toggleButton.parents('.js-site-section-nav-item').eq(0).children('.lhs-nav-list');
        event.preventDefault();
        childLists.each(function (i, el) {
            var expandedState = $(el).attr('aria-expanded'),
                newState = 'true';
            if (typeof expandedState !== 'undefined' && expandedState === 'true') {
                newState = 'false';
            }
            $(el).slideToggle('fast').attr('aria-expanded', newState);
        });
        toggleButton.toggleClass('lhs-nav-list__item-cta--expanded');
    },
    autoExpandCurrent: function () {
        var $arrowsToExpand = $lhsNav.find('.lhs-nav-list__item-link--active .lhs-nav-list__item-cta, .lhs-nav-list__item-link--current .lhs-nav-list__item-cta'); /** looking for the
        active, since the active state is always the parent of a current state. Unless we're talking about the level 3
        being the current one (the level 2 is the heading of the nav and doesn't have the class active)
        **/

        if ($arrowsToExpand.length) {
            //The current is at the level 4 or 5. Lets expand the parents.
            $arrowsToExpand.trigger('click');
        } else {
            //The current is at the level 3. Lets look for it and expand it.
            $lhsNav.find('.lhs-nav-list__item--current')
                .addClass('lhs-nav-list__item--active')
                .find('.lhs-nav-list__item-cta').eq(0)
                .trigger('click');
        }
    }
};

(function () {
    lhsNavObj.init();
} (jQuery));
