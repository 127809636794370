var $headerSearchWrapper = $(".header-row__search"),
  $headerSearchToggle = $headerSearchWrapper.find(".header-row__search-icon"),
  $headerSearchClose = $headerSearchWrapper.find(
    ".header-row__search-close--btn"
  ),
  $slidingPanel = $(".header-row__search-form"),
  $searchInput = $slidingPanel.find("#quick-search__query, #query");

var headerSearch = {
  init: function() {
    var thisObj = this;
    thisObj.setListeners();
    thisObj.handleSubmission();
    thisObj.handleMobileDesktopSwitch(); //Switch the DOM position of the search for mobile/destop
  },
  setListeners: function() {
    var headerSearchObj = this;
    $headerSearchToggle.on("click", headerSearchObj.handleSearchFormExpand);
    $headerSearchClose.on("click", headerSearchObj.handleCloseSearchForm);

    $("body").on("keyup", function(e) {
      if (e.key === "Escape") {
        headerSearchObj.handleCloseSearchForm();
      }
    });

    $headerSearchWrapper
      .find("input")
      .bind("focus blur", headerSearchObj.disableAutoZoom); //Disable auto zoom in iOS

    $searchInput.on("keyup", headerSearchObj.handleInputChange.bind(this));

    $searchInput.on("focus", function(e) {
      $(".tt-menu").show();
      headerSearchObj.handleInputChange(e);
    });
    $searchInput.on("blur", function() {
      $(".tt-menu").hide();
    });
  },
  handleInputChange: function(e) {
    var self = this;
    if (e.target.value !== "") {
      $(".tt-dataset:not(.tt-dataset-matrix)").show();

      $(".tt-dataset-matrix").each(function() {
        $(this).hide();
      });
      $(document).ajaxStop(function() {
        self.handleCategoryLinkChange();
      });
    } else {
      $(".tt-dataset:not(.tt-dataset-matrix)").each(function() {
        $(this).hide();
      });
      $(".tt-menu").show();
      $(".tt-dataset-matrix").show();
    }
  },
  handleCategoryLinkChange: function() {
    var categoryLinks = $(".tt-dataset ").find("[data-tab-link]");
    var profileValue = $("input[name='profile']").val();
    categoryLinks.each(function() {
      var link = $(this).data("tab-link");
      $(this)
        .siblings(".tt-category")
        .children("a")
        .attr("href", link.replace("?", "?profile=" + profileValue + "&"));
    });
  },
  handleCloseSearchForm: function() {
    // close search header
    $(".header-row__search-form").removeClass(
      "header-row__search-form--expanded"
    );

    //Remove class to body
    $("body").removeClass("header__search--open");

    $headerSearchWrapper.removeClass("header-row__search--active");
  },
  handleSearchFormExpand: function(e) {
    e.preventDefault();

    //Handle opening/closing the search form
    $slidingPanel.toggleClass("header-row__search-form--expanded");
    $headerSearchWrapper.toggleClass("header-row__search--active");

    //Add class to body
    $("body").addClass("header__search--open");

    //Clear input and focus
    setTimeout(function() {
      $searchInput.val("");
      $searchInput.focus();
    }, 500);
  },
  handleMobileDesktopSwitch: function() {
    var minMq = "(min-width: 60em)",
      $searchForm = $(".header-row__search-form");

    window.matchMedia(minMq).addListener(function(mql) {
      if (mql.matches) {
        $searchForm.appendTo($(".header-row__search"));
      } else {
        $searchForm.insertAfter($(".header-row__search").parent());
      }
    });

    if (!window.matchMedia(minMq).matches) {
      $searchForm.insertAfter($(".header-row__search").parent());
    }
  },
  disableAutoZoom: function(event) {
    var $viewportMeta = $('meta[name="viewport"]');
    $viewportMeta.attr(
      "content",
      "width=device-width,initial-scale=1,maximum-scale=" +
        (event.type === "blur" ? 10 : 1)
    );
  },
  handleSubmission: function() {
    //TBC
  }
};

(function() {
  if ($headerSearchWrapper.find("form").length !== 0) {
    headerSearch.init();
  }
})(jQuery);
