/* global Handlebars, expertlineGatewayURL */

/*
*   Table of contents:
*   
*       1. Expertline JS
*           1.1 Define vars
*           1.2 Helpers
*           1.3 Initiate
*           1.4 Error message
*           1.5 Loader
*           1.6 Process search query
*           1.7 Build page
*           1.8 Switch page
*           1.9 Manage Query
*           1.10 Init onPopState
*           1.11 Prefill the form based on query
*
*       2. Initiate
*
*/
(function($) {

    /*
    * 1. Expertline JS
    */
    var FBGatewayURL = ''; // default 
    if (typeof expertlineGatewayURL !== 'undefined') {
        FBGatewayURL = expertlineGatewayURL;
    }

    var expertline = {

        // 1.1 Define vars
        variables: {
            xhr:        undefined,
            gatewayURL: FBGatewayURL
        },

        // 1.2 Helpers
        helpers: {
            buildHandlebars: function(templateSrc, json) {
                //var template = templateSrc.html().replace(/\[\[/g,'\{\{').replace(/\]\]/g,'\}\}'), // Remove replace on prod
                var template = templateSrc.html(),
                    compiled = Handlebars.compile(template),
                    html = compiled(json);

                return html;
            },
            centerOn: function(obj) {
                var $this = obj,
                    posY = $this.offset().top,
                    $thisHeight = $this.outerHeight(),
                    windowHeight = $(window).height(),
                    scrollPos = posY - windowHeight/2 + $thisHeight/2;
                
                $('html, body').animate({scrollTop: scrollPos}, 200); 
            },
            registerHandlebarsHelpers: function() {
                
                // If
                Handlebars.registerHelper('if_eq', function(a, b, opts) {
                    if(a === b) {
                        return opts.fn(this);
                    } else {
                        return opts.inverse(this);
                    }
                });

                // If not
                Handlebars.registerHelper('if_neq', function(a, b, opts) {
                    if(a === b) {
                        return opts.inverse(this);
                    } else {
                        return opts.fn(this);
                    }
                });

                // If less then or equal
                Handlebars.registerHelper('if_less_eq', function(a, b, opts) {
                    if(a <= b) {
                        return opts.fn(this);
                    } else {
                        return opts.inverse(this);
                    }
                });

                // Slice by delimiter
                Handlebars.registerHelper('sliceBy', function(a, b, opts) {
                    var returnObj = {'items': a.split(b)};
                    return opts.fn(returnObj);
                });

                // Remove spaces
                Handlebars.registerHelper('removeSpaces', function(context, options) {
                    return options.fn(context.replace(/ /g, ''));
                });

                // show JSON - for debug 
                Handlebars.registerHelper('json', function(context) {
                    return JSON.stringify(context);
                });

                // Pagination
                Handlebars.registerHelper('pagination', function(resultsSummary, QueryString, num, returnPrevHref, returnNextHref, opts) {
                    
                    // Inner helpers
                    function removeFromQuery(query, removeArray) {
                        var queryArray = query.split('&'),
                            queryObj = {},
                            returnArray = [];

                        // Create object from query
                        for (var i=0; i<queryArray.length; i+=1) {
                            queryObj[queryArray[i].split('=')[0]] = queryArray[i].split('=')[1];
                        }

                        // Remove from object elements from removeArray
                        for (var j=0; j<removeArray.length; j+=1) {
                            delete queryObj[removeArray[j]];
                        }

                        // Create output array
                        for (var prop in queryObj) {
                            if (queryObj.hasOwnProperty(prop)) {
                                returnArray.push(prop + '=' + queryObj[prop]);
                            }
                        }

                        // Return output as string
                        return returnArray.join('&');
                    }

                    // Return num ranks
                    function returnStartRank(resultsSummaryData, page) {
                        return (1 + resultsSummary.numRanks * (page-1));
                    }

                    // Sort array
                    function sortResultsArray(a,b) {
                        if (a.val < b.val) {
                            return -1;
                        } else if (a.val > b.val) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }

                    // Set variables
                    var pageCount = Math.ceil(resultsSummary.totalMatching/resultsSummary.numRanks),
                        currentPage = Math.ceil(resultsSummary.currStart/resultsSummary.numRanks),
                        results = [],
                        removeArray = ['start_rank','collection'],
                        i = 0,
                        up = 0,
                        down = 0,
                        num = num <= pageCount ? num : pageCount;

                    // Return prev page href
                    if (returnPrevHref) {
                        return (removeFromQuery(QueryString, removeArray)+'&start_rank='+resultsSummary.prevStart);
                    }

                    // Return next page href
                    if (returnNextHref) {
                        return (removeFromQuery(QueryString, removeArray)+'&start_rank='+resultsSummary.nextStart);
                    }

                    // Push current page
                    results.push({'url': '','val': currentPage});

                    // Create the pagination array of $num elements
                    while(i < num-1) {
                        up+=1;
                        down-=1;
                        
                        // Generate next page element
                        if (currentPage + up <= pageCount) {
                            results.push({'url': removeFromQuery(QueryString, removeArray)+'&start_rank='+returnStartRank(resultsSummary, currentPage + up), 'val': currentPage + up});
                            i+=1;
                        }

                        // Generate prev page link
                        if (currentPage + down > 0) {
                            results.push({'url': removeFromQuery(QueryString, removeArray)+'&start_rank='+returnStartRank(resultsSummary, currentPage + down), 'val': currentPage + down});
                            i+=1;
                        }

                    }
                    
                    // Sort the array
                    results.sort(sortResultsArray);

                    // Return array of obejcts containing pagination items
                    return opts.fn(results);
                });

            }

        },

        // 1.3 Initiate
        init: function() {
            var context = this;

            // Prevent default form submit
            $('.js-expertline__form').on('submit', function(){
                return false;
            });

            // Bind search submit action
            $('.js-expertline__form-submit').on('click', function(e){
                e.preventDefault();
                
                context.loader(true);
                context.processQuery($('.js-expertline__form').serialize());    
            });

            // Bind pagination links
            $('body').on('click','.js-expertline__results-pagination-link', function(e){
                e.preventDefault();

                context.loader(true);
                context.processQuery($(this).attr('href'));  
            });

            // Expand the tile
            $('body').on('click','.js-expertline__results-item-name-link, .js-expertline__results-item-expand', function(e){ //eslint-disable-line max-len
                e.preventDefault();
                $(this).parents('.expertline__results-item').addClass('expertline__results-item--expanded');
            });

            // Collapse the tile
            $('body').on('click','.js-expertline__results-item-collapse', function(e){
                e.preventDefault();
                $(this).parents('.expertline__results-item').removeClass('expertline__results-item--expanded');
            });

            // Create the loader
            var loaderString = '<div class = "clearfix">&nbsp;</div><div class="spinner view-more"><div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div></div>'; //eslint-disable-line max-len
            $('.js-expertline__loader').prepend(loaderString);

            // Register Handlebars Helpers 
            this.helpers.registerHandlebarsHelpers();

            // Check query on init
            if (context.manageQuery(null,true)) {
                context.loader(true);
                context.fillTheForm(context.manageQuery(null,true), $('.js-expertline__form'));
                context.processQuery(context.manageQuery(null,true));  
            }

            // Handle popState
            context.initOnpopstate();

        },

        // 1.4 Error message
        errorMsg: function(status) {
            var context = this;

            if (status === true) { // show error msg
                $('.js-expertline__form-no-results').stop(true,true).fadeOut('fast');
                $('.js-expertline__form-no-results').attr('aria-hidden','true');

                $('.js-expertline__form-error').stop(true,true).fadeIn('fast', function(){
                    context.helpers.centerOn($('.js-expertline__form-error'));
                });
                $('.js-expertline__form-error').attr('aria-hidden','false');
            } else if (!status) { // hide all errors
                $('.js-expertline__form-error').stop(true,true).fadeOut('fast');
                $('.js-expertline__form-error').attr('aria-hidden','true');
                $('.js-expertline__form-no-results').stop(true,true).fadeOut('fast');
                $('.js-expertline__form-no-results').attr('aria-hidden','true');
            } else if (status === 'no-results') { // show no results msg
                $('.js-expertline__form-error').stop(true,true).fadeOut('fast');
                $('.js-expertline__form-error').attr('aria-hidden','true');

                $('.js-expertline__form-no-results').stop(true,true).fadeIn('fast', function(){
                    context.helpers.centerOn($('.js-expertline__form-no-results'));
                });
                $('.js-expertline__form-no-results').attr('aria-hidden','false');
            }
        },

        // 1.5 Loader
        loader: function(status) {
            var context = this;

            if (status) {
                $('.js-expertline__loader').attr('aria-live','polite');
                $('.js-expertline__loader').stop(true,true).fadeIn('fast', function(){
                    context.helpers.centerOn($('.js-expertline__loader'));
                });
            } else {
                $('.js-expertline__loader').attr('aria-live','off');
                $('.js-expertline__loader').stop(true,true).fadeOut('fast');
            }
        },

        // 1.6 Process search query
        processQuery: function(attr, pushHistory) {
            var context = this;
            
            // Set default
            if (undefined === pushHistory) {
                pushHistory = true;
            }

            // Hide search results
            $('.js-expertline__results').fadeOut('fast');

            // Hide error
            context.errorMsg(false);

            // Cancel prev call
            if(context.variables.xhr && context.variables.xhr.readyState !== 4){
                context.variables.xhr.abort();
            }

            // Make the ajax call to FB REST asset
            context.variables.xhr = $.ajax({
                url:      context.variables.gatewayURL + '?' + attr,
                dataType: 'json'
            }).success(function(json){
                context.manageQuery(attr, false, pushHistory);
                context.pageBuild.searchResults(json, context);
            }).error(function(e){
                context.loader(false);
                if (e.status !== 0 ) {
                    context.errorMsg(true);
                }
            });

        },

        // 1.7 Build page
        pageBuild: {

            // Search
            searchResults: function(json, context) {
                var html = '';
                
                // Hide the loader
                context.loader(false);

                // Check are there results
                if (!json.response.resultPacket) { // resultsSummary = null
                    context.errorMsg('no-results');
                } else if (json.response.resultPacket.hasOwnProperty('resultsSummary')) { // resultsSummary !== null
                    if (json.response.resultPacket.resultsSummary.totalMatching === 0) { // but got no results
                        context.errorMsg('no-results');
                    } else { // has results
                        html = context.helpers.buildHandlebars($('#js-expertline__templates-search-results'), json);
                        $('.js-expertline__results').html(html);
                        context.pageSwitch.searchResults(context);
                    }
                } else { // some other error
                    context.errorMsg(true);
                }

            }

        },

        // 1.8 Switch page
        pageSwitch: {

            // Search
            searchResults: function(context) {

                // Hide loader
                context.loader(false);

                // Switch from search                
                $('.js-expertline__results').slideDown('fast');

            }

        },

        // 1.9 Manage Query
        manageQuery: function(query, get, pushHistory) {

            // Check support 
            if (!history.pushState) {
                return false;
            }

            // Set default
            if (undefined === pushHistory) {
                pushHistory = true;
            }

            // Get hash
            if (undefined !== query && !get && pushHistory) {
                var pathname = window.location.pathname;
                history.pushState(null, 'Search results', pathname+'?'+query);
            }

            // Set hash
            if (get) {
                var queryString = window.location.search.replace('?','');

                if (queryString === '') {
                    return false;
                } else {
                    return queryString;
                }
            }

        },

        // 1.10 Init onPopState
        initOnpopstate: function() {
            var context = this;

            if (undefined !== window.onpopstate) {
                window.onpopstate = function() {
                    var queryString = document.location.search.replace('?','');

                    if (undefined !== queryString && queryString !== '') {
                        context.loader(true);
                        context.processQuery(queryString, false);
                    }

                };
            }
        },

        // 1.11 Prefill the form based on query
        fillTheForm: function(query, form) {
            var queryArray = query.split('&'),
                queryObj = {};

            // Create object from query
            for (var i=0; i<queryArray.length; i+=1) {
                queryObj[queryArray[i].split('=')[0]] = decodeURIComponent(queryArray[i].split('=')[1].replace(/\+/g,' '));
            }

            // Set values
            for (var prop in queryObj) {
                if (queryObj.hasOwnProperty(prop)) {
                    var element = form.find('*[name="'+prop+'"]');
                    element.val(queryObj[prop]);
                }
            }

        },

    };

    /*
    * 2. Initiate
    */
    if ($('.js-expertline').length) {
        expertline.init();
    }

}(jQuery));