/*global gADataLayerPushEvents */

(function($){

    var bannerCarousel = $('.banner__carousel');

    // Inject play/pause control on carousel init.
    bannerCarousel.on('init', function(ev, slick) {
        var carouselTogglePause;
        // Inject toggle pause button
        carouselTogglePause = $('<button type="button" class="slick-toggle-pause">Toggle Pause</button>')
            .appendTo(slick.$slider);
        // Set initial state of play/pause button based on carousel's current play/pause state
        carouselTogglePause.attr('aria-pressed', slick.paused);
        // When pause toggle is clicked, toggle the carousel state correspondingly
        carouselTogglePause.on('click.togglePause', slick, function() {
            if (slick.paused) {
                slick.$slider.slick('slickPlay');
            }
            else {
                slick.$slider.slick('slickPause');
            }
            // Change button state to match carousel state
            carouselTogglePause.attr('aria-pressed', slick.paused);
        });
        // Push carousel control activated event to Google Analytics
        $(slick.$slider).on('click', '.slick-dots > li, .slick-arrow, .slick-toggle-pause', {
            targetCarousel: slick
        }, function(gaEvent) {
            var carouselControl = gaEvent.currentTarget,
                analyticsData = {
                    'event':       'carouselControl',
                    'eventAction': 'navigate-key'
                },
                currentSlide = gaEvent.data.targetCarousel.currentSlide,
                paused = gaEvent.data.targetCarousel.paused,
                actionMap = [{
                    matchClass:  'slick-prev',
                    eventAction: 'navigate-previous'
                }, {
                    matchClass:  'slick-next',
                    eventAction: 'navigate-next'
                }, {
                    matchClass:  'slick-toggle-pause',
                    eventAction: 'navigate-pause'
                }];
            // Which control was activated?
            // (Will fallback to "slick dot" carousel indicator as default carousel control)
            actionMap.forEach(function(item) {
                var currentClass = item.matchClass,
                    currentAction = item.eventAction;
                // Match event action to carousel control class name
                if ($(carouselControl).hasClass(currentClass)) {
                    analyticsData.eventAction = currentAction;
                    // Special case: check if pause button was toggled off
                    // (ie. current state is now play instead of pause)
                    if (currentAction === 'navigate-pause' && !paused) {
                        analyticsData.eventAction = 'navigate-play';
                    }
                }
            });
            // Always capture current slide (after slide change)
            analyticsData.eventValue = currentSlide;
            // Push data to Google Analytics
            gADataLayerPushEvents(analyticsData);
        });
    });

    // Push banner change event to Google Analytics
    var bannerSlideChangeGAhelper = function(event, heading, slideNr) { // eslint-disable-line no-unused-vars
        var bannerDisplayedGAObj = {
            'event':          event,
            'bannerTitle':    heading,
            'bannerPosition': slideNr
        };
        gADataLayerPushEvents(bannerDisplayedGAObj);
    };

    const bannerSlideChangeUpdatePalette = function bannerSlideChangeUpdatePalette($slider, $nextSlideHeading) {
      if (!$slider || !$nextSlideHeading) {
        return;
      }

      const PALETTE_PREFIX = 'user-def-palette--text-';
      const PALETTE_ATTR = 'data-heading-palette';

      const headingClassList = $nextSlideHeading.attr('class').split(' ');
      const paletteClassList = headingClassList.filter(item => item.indexOf(PALETTE_PREFIX) === 0);
      const paletteToken = paletteClassList[0] ? paletteClassList[0].replace(PALETTE_PREFIX, '') : '';

      $slider.attr(PALETTE_ATTR, paletteToken);
    };

    bannerCarousel.on('init', function (slick) {
      const $slider = $(slick.currentTarget);
      const $firstSlideHeading = $slider.find('.slick-slide').eq(0).find('.banner__heading');

      // analytics
      const firstSlideHeading = $firstSlideHeading.html() || '';
      function waitForDataLayer() {
        if (typeof gADataLayerPushEvents !== 'undefined' && $.isFunction(gADataLayerPushEvents)) {
          bannerSlideChangeGAhelper('Banner Impression', firstSlideHeading, 1);
        } else {
          setTimeout(waitForDataLayer, 100);
        }
      };
      waitForDataLayer();

      // WEBSW-1986 - pass first slider colour palette configs to root container for design theme update
      bannerSlideChangeUpdatePalette($slider, $firstSlideHeading);
    });

    bannerCarousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      const $slider = slick.$slider;
      const $nextSlide = $(slick.$slides[nextSlide]);

      // analytics
      const $nextSlideHeading = $nextSlide.find('.banner__heading');
      const nextSlideHeadingHtml = $nextSlideHeading.html() || '';
      bannerSlideChangeGAhelper('Banner Impression', nextSlideHeadingHtml, nextSlide + 1);

      // WEBSW-1986 - pass slider colour palette configs to root container for design theme update
      bannerSlideChangeUpdatePalette($slider, $nextSlideHeading);
    });

    // Init banner carousel
    bannerCarousel.slick({
        dots:           true,
        arrows:         true,
        slide:          '.banner__carousel-slide',
        autoplay:       true,
        fade:           true,
        pauseOnHover:   false,
        waitForAnimate: false
    });

}(jQuery));
