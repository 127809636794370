// Enable click to expand the sharethis menu to bypass keyboard trap
var addthis_config = addthis_config||{};
addthis_config.ui_click = true;

(function() {

    // Make sharethis menu go away on desktop
    $('.social-media-share').on('mouseout', '.addthis_button_compact', function() {
        $('#at_hover').mouseover().mouseout();
    });
}(jQuery));
