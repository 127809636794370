var searchGlobal = {
  selector: "#page-wrapper",
  classInput: ".search-global__banner .tt-input",
  addClass: "overlay",
  searchInput: $("#main-query"),
  dropdownButton: ".dropdown-toggle",

  init: function() {
    var self = this;
    self.bind();
  },

  bind: function() {
    var self = this;

    // add class for overlay
    $(self.classInput).on("focus", function(e) {
      $(self.selector).addClass(self.addClass);
      $(".search-global .tt-menu").show();
      self.handleChangeInput(e);
    });

    self.searchInput.on("keyup", self.handleChangeInput.bind(this));

    // delete class for overlay
    $(self.classInput).on("blur", function() {
      $(self.selector).removeClass(self.addClass);
      $(".search-global .tt-menu").hide();
    });
  },
  handleChangeInput: function(e) {
    var self = this;
    if (e.target.value !== "") {
      $(".search-global .tt-dataset:not(.tt-dataset-matrix)").show();
      $(".search-global .tt-dataset-matrix").each(function() {
        $(this).hide();
      });
      $(document).ajaxStop(function() {
        self.handleCategoryLinkChange();
      });
    } else {
      $(".search-global .tt-dataset:not(.tt-dataset-matrix)").each(function() {
        $(this).hide();
      });
      $(".search-global .tt-menu").show();
      $(".search-global .tt-dataset-matrix").show();
    }
  },
  handleCategoryLinkChange: function() {
    var categoryLinks = $(".tt-dataset ").find("[data-tab-link]");
    var profileValue = $("input[name='profile']").val();
    categoryLinks.each(function() {
      var link = $(this).data("tab-link");
      $(this)
        .siblings(".tt-category")
        .children("a")
        .attr("href", link.replace("?", "?profile=" + profileValue + "&"));
    });
  }
};

var searchFacetMobile = {
  facets: ".search-facets",
  btn: ".search-facet__btn",
  closeBtn: ".search-facet__btn--close",
  factet: ".search-facet",
  init: function() {
    this.bind();
    this.handleMobileDesktopSwitch();
  },
  bind: function() {
    $(this.btn).on("click", this.handleOpen.bind(this));
    $(this.closeBtn).on("click", this.handleClose.bind(this));
    this.hendleAccordion();
  },
  handleOpen: function() {
    $("body").addClass("search-facets__modal--open");
    $(this.facets).addClass("search-facets__modal");
  },
  handleClose: function() {
    $("body").removeClass("search-facets__modal--open");
    $(this.facets).removeClass("search-facets__modal");
  },
  handleMobileDesktopSwitch: function() {
    var self = this;
    var minMq = "(min-width: 960px)";

    window.matchMedia(minMq).addListener(function(mql) {
      if (mql.matches) {
        self.handleClose();
      }
    });
  },
  hendleAccordion: function() {
    var self = this;
    $(this.factet).each(function() {
      var $title = $(this).find(".card-title");
      $title.on("click", self.hendleClickTitle.bind(this));
    });
  },
  hendleClickTitle: function() {
    $(this).toggleClass("search-facet--open");
  }
};

setTimeout(function() {
  searchGlobal.init();
  searchFacetMobile.init();
}, 0);

// $(window).load(function() {
//   "use strict";
//   searchGlobal.init();
//   searchFacetMobile.init();
// });
