/*global $, showMoreInit, initTooltips, vars, initPopupVideos*/

/*
 *   Table of contents:
 *
 *       1. CourseSlider constructor
 *       2. CourseSlider assign
 */

// 1. CourseSlider constructor
var CourseSlider = function CourseSlider(options) {
  'use strict';

  this.options = options;
  this.loaded = false;
  this.xhr = undefined;

  // Init - check
  this.init = function() {
    if (
      this.options.parent.length &&
      this.options.target.length &&
      this.options.trigger.length
    ) {
      this.bind();

      // Create the loader
      var loaderString = vars.spinnerHTML;
      this.options.parent.before(
        '<div class="detail-slider-preloader">' + loaderString + '</div>'
      );
    }
  };

  // Bind clicks that trigger the slider
  this.bind = function() {
    var _this = this,
      minMq = '(min-width: 60em)',
      mobileReplaceString = '';

    // Bind trigger
    options.trigger.on('click', function(e) {
      e.preventDefault();

      /**
                On mobile, the tabs-to-accordions module adds the '-mobile' to the IDs after cloning
                them into the accordion. That has to be considered - i.e. not replaced by '' in the condition guard
                below that checks if target's contents are already loaded. Otherwise, it will load them twice causing a
                duplication issue.

                Note: '!window.matchMedia(minMq).matches' means we're in a mobile breakpoint
            **/
      mobileReplaceString = !window.matchMedia(minMq).matches ? '' : '-mobile';

      if (
        _this.options.target.find(
          'div[rel="' +
            $(this)
              .attr('id')
              .replace(mobileReplaceString, '') +
            '"]'
        ).length
      ) {
        _this.showLoadedSlide(
          $(this)
            .attr('id')
            .replace(mobileReplaceString, '')
        );
      } else {
        _this.ajaxCall($(this));
      }
    });

    // Bind back button
    $('body').on('click', '.slide-back', function() {
      _this.hideSlide($(this));
    });

    /**
            Hide the active loaded slides when a breakpoint change is detected, to avoid having two slides
            displayed at a time once the viewport comes back again to the initial state
        **/
    window.matchMedia(minMq).addListener(function() {
      $('.slide-back').each(function() {
        _this.hideSlide($(this));
      });
    });
  };

  // Show loader
  this.loader = function(status) {
    if (status) {
      this.options.parent.prev().addClass('loading');
    } else {
      this.options.parent.prev().removeClass('loading');
    }
  };

  // Make ajax call
  this.ajaxCall = function(triggerItem) {
    var _this = this;

    // abort last ajax call
    if (this.xhr && this.xhr.readyState !== 4) {
      this.xhr.abort();
    }

    // Set target height
    this.options.target.css('height', this.options.parent.height());

    // Hide parent
    this.options.parent.fadeOut('fast');

    // Show target
    this.options.target.fadeIn('fast', function() {
      // Show loader
      _this.loader(true);

      // Fetch slider html
      var triggerItemHref =
        triggerItem.attr('data-href') !== '' &&
        triggerItem.attr('data-href') !== undefined
          ? triggerItem.attr('data-href')
          : triggerItem.attr('href');
      _this.xhr = $.ajax({
        url: triggerItemHref
      })
        .success(function(data) {
          _this.loader(false);
          _this.createSlide(data, triggerItem.attr('id'));
        })
        .error(function() {
          _this.loader(false);
          _this.options.parent.fadeIn('fast');
        });
    });
  };

  // Create new slide
  this.createSlide = function(data, rel) {
    // Create new slide
    var newItem = $('<div class="slideItem out" rel="' + rel + '" />');
    newItem.append($(data).html());

    // Append new slide
    this.options.target.append(newItem);

    showMoreInit(); // source/modules/lhs-content/js
    initTooltips(); // Study.Monash repo: source/modules/at-a-glance/js
    initPopupVideos(newItem.find('.popup__video')); // source/modules/image-video-gallery
    newItem.find('.accordion').UberAccordion({
      // eslint-disable-line new-cap
      buttonClass: 'accTitle',
      toggle: true,
      multiple: true,
      expandOnFocus: false,
      preventDefaultButton: true,
      hashChange: true
    });

    // Show slide
    this.showSlide(rel);
  };

  // Show loaded slide
  this.showLoadedSlide = function(rel) {
    var _this = this;

    // Set target height
    this.options.target.css('height', this.options.parent.height());

    this.options.parent.fadeOut('fast', function() {
      _this.options.target.fadeIn('fast', function() {
        _this.showSlide(rel);
      });
    });
  };

  // Show slide
  this.showSlide = function(rel) {
    var _this = this;

    this.options.target.find('div[rel="' + rel + '"]').show();

    setTimeout(function() {
      _this.options.target.find('div[rel="' + rel + '"]').removeClass('out');
    }, 500);

    // Remove set height
    this.options.target.attr('style', '');

    // Scroll to top
    setTimeout(function() {
      $('html, body').animate(
        {
          scrollTop: _this.options.target.offset().top
        },
        100
      );
    }, 500);
  };

  // Hide slide
  this.hideSlide = function(backButton) {
    var currentSlide = backButton.parents('.slideItem:first'),
      _this = this;

    currentSlide.addClass('out');

    setTimeout(function() {
      currentSlide.hide();
      _this.options.parent.fadeIn('fast');
      _this.options.target.fadeOut('fast');
    }, 700);
  };
};

// 2. CourseSlider assign
$(document).ready(function() {
  'use strict';

  // Overview
  if ($('#overview-tab-content').length) {
    var overviewSlide = new CourseSlider({
      parent: $('#overview-tab-content'),
      target: $('#overview-slider-content'),
      trigger: $('#overview-tab-content a.slideRightBtn')
    });
    overviewSlide.init();
  }

  if ($('#course-structure-tab-content').length) {
    var courseStructureSlide = new CourseSlider({
      parent: $('#course-structure-tab-content'),
      target: $('#course-structure-slider-content'),
      trigger: $('#course-structure-tab-content a.slideRightBtn')
    });
    courseStructureSlide.init();
  }
});
